import IndicadorRealBusiness from "@/business/indicadores/IndicadorRealBusiness.js";

export default {

  campos() {
    return [
      {nome: 'Texto', id: 'INPUT', icon: 'fal fa-font'},
      {nome: 'Data', id: 'DATE', icon: 'fal fa-calendar'},
      {nome: 'Número', id: 'NUMBER', icon: 'fal fa-hashtag'},
      {nome: 'Moeda', id: 'CURRENCY', icon: 'fal fa-usd-circle'},
      {nome: 'Sim/Não', id: 'BOOLEAN', icon: 'fal fa-check-square'},
      {nome: 'Condições Personalizadas', id: 'SELECT', icon: 'fal fa-list-ol'},
      {nome: 'Lista Predefinida', id: 'LIST', icon: 'fal fa-list-alt'},
      {nome: 'Título Estático', id: 'LABEL', icon: 'fal fa-info-square'},
      {nome: 'Texto Formatado', id: 'EDITOR', icon: 'fal fa-file-alt'},
      {nome: 'Grupo', id: 'GROUP', icon: 'fal fa-object-group'},
      {nome: 'Vinculo', id: 'VINCULO', icon: 'fal fa-link'},
      {nome: 'Fragmento de Checklist', id: 'CHECKLIST', icon: 'fal fa-external-link-alt'},
      {nome: 'Condição Automática', id: 'AUTOMATICO', icon: 'fal fa-list-ol'},
      {nome: 'Upload de Imagem', id: 'IMAGEM', icon: 'fal fa-upload'},
      {nome: 'Exigência', id: 'EXIGENCIA', icon: 'fal fa-exclamation-triangle'},
    ]
  },

  mascaras() {
    return [
      {nome: 'Nenhum', id: 'text'},
      {nome: 'CPF', id: 'cpf'},
      {nome: 'CNPJ', id: 'cnpj'},
    ]
  },

  vinculos() {
    return [
      {nome: 'Indicador Real', id: 'INDICADOR_REAL'},
      {nome: 'Indicador Real - Ato', id: 'ATO_INDICADOR_REAL'},
      {nome: 'Registro Auxiliar', id: 'REGISTRO_AUXILIAR'},
      {nome: 'Registro Auxiliar - Ato', id: 'ATO_REGISTRO_AUXILIAR'},
      {nome: 'Indicador Pessoal', id: 'INDICADOR_PESSOAL'},
      {nome: 'Protocolo', id: 'DOCUMENTO'},
      {nome: 'Título', id: 'TITULO'},
    ]
  },

  apresentacao() {
    return [
      {nome: 'Select', id: 'select'},
      {nome: 'Checkbox', id: 'checkbox'},
      {nome: 'Radio', id: 'radio'},
    ]
  },

  apresentacaoSingular() {
    return [
      {nome: 'Oculto', id: 'hidden'},
      {nome: 'Select', id: 'select'},
      {nome: 'Radio', id: 'radio'},
    ]
  },

  apresentacaoTitulo() {
    return [
      {nome: 'Select', id: 'select'},
      {nome: 'Radio', id: 'radio'},
    ]
  },

  exigencia() {
    return [
      {nome: 'Individual', id: 'individual'},
      {nome: 'Não Selecionados', id: 'unchecked'},
      {nome: 'Geral - Nenhum Selecionado', id: 'vazio'},
      {nome: 'Geral - Seleção incompleta', id: 'incompleto'},
    ]
  },

  multiplicador() {
    return [
      {nome: 'Manual', id: 'manual'},
      // {nome: 'Matrículas', id: 'matricula'},
      {nome: 'Envolvidos', id: 'envolvido'},
    ]
  },

  automacao() {
    return [
      {id: 'CATEGORIA_IMOVEL', nome: 'Categoria do Imóvel', condicoes : ['Urbano', 'Rural']},
      {id: 'ESTADO_CIVIL', nome: 'Estado Civil', condicoes : ['Solteiro', 'Casado', 'Separado', 'Divorciado', 'Viúvo', 'Não Informado', 'União Estável']},
      {id: 'TIPO_PESSOA', nome: 'Tipo de Pessoa', condicoes : ['Pessoa física', 'Pessoa jurídica']},
      {id: 'POSSUI_DEVEDOR', nome: 'Ato Possui Devedor?', condicoes : ['Sim', 'Não']},
      {id: 'UNIDADE_AUTONOMA', nome: 'Imóvel é Unidade Autônoma?', condicoes : ['Sim', 'Não']},
      {id: 'INDIVIDUO_MENOR', nome: 'Menor?', condicoes : ['Sim', 'Não']},
      {id: 'INDIVIDUO_INCAPAZ', nome: 'Incapaz?', condicoes : ['Sim', 'Não']},
      {id: 'INDIVIDUO_FALECIDO', nome: 'Falecido?', condicoes : ['Sim', 'Não']},

      {id: 'REGIME_CASAMENTO', nome: 'Regime do Casamento', condicoes : ["Comunhão Parcial","Comunhão Universal","Separação Total","Participação Final nos Aquestos","Separação Obrigatória","Casamento no Exterior","Desconhecido"]},
      {id: 'CASAMENTO_APOS_LEI_6515', nome: 'Casamento após lei 6515?', condicoes : ['Sim', 'Não']},

      {id: 'LIVRO', nome: 'Livro do Ato', condicoes : ['MATRICULA', 'REGISTRO_AUXILIAR', 'TRANSCRICAO']},

      {id: 'PAPEL', nome: 'Papel', condicoes : IndicadorRealBusiness.getPapeisEnvolvidos().map(e => e.nome)},
    ]
  },

  titulo() {
    return [
      {nome: 'Label', id: 'LABEL'},
      {nome: 'Alerta', id: 'ALERT'},
    ]
  },

}
