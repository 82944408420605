import Http from "@/commons/Http";

const path = 'api/atribuicao';

export default {

  async getAtribuicoes(dto){
    return Http.get(`${path}`,{
      params:{
        "dominio":dto.dominio,
        "idEtapa":dto.etapa,
        "idDepartamento":dto.departamento
      }
    });
  },

  async getByEtapa(etapa){
    return Http.get(`${path}/etapa/${etapa}`);
  },

  async listarAtribuidos(etapa, tipoServico){
    return Http.get(`${path}/listar-atribuidos/${etapa}/${tipoServico}`);
  },

  async getByEtapaDepartamento(etapa, departamento){
    return Http.get(`${path}/etapa-departamento/${etapa}/${departamento}`);
  },

  async colarEtapa(etapa, departamento, atribuicoes){
    return Http.post(`${path}/etapa-colar/${etapa}/${departamento}`, atribuicoes);
  },

  async colarEtapaGeral(etapaOrigem, etapaDestino, departamento, dominio){
    return Http.post(`${path}/etapa-colar/${etapaOrigem}/${etapaDestino}/${departamento}/${dominio}`);
  },

  async inserirAtribuicao(tipo, usuario, etapa) {
    return Http.post(`${path}/${tipo}/${usuario}/${etapa}`);
  },

  async selecionarAtribuicao(dto) {
    return Http.post(`${path}/selecionar-atribuicao`, dto);
  },

  async inserirAtribuicaoUsuario(usuario_copia, usuario_destino, etapa_copia, etapa_destino) {
    return Http.post(`${path}/copiar-usuario/${usuario_copia}/${usuario_destino}/${etapa_copia}/${etapa_destino}`);
  },

  async inserirAtribuicaoTipoUsuario(tipo_copia, tipo_destino, etapa_copia, etapa_destino, departamento) {
    return Http.post(`${path}/copiar-tipo/${tipo_copia}/${tipo_destino}/${etapa_copia}/${etapa_destino}/${departamento}`);
  },

  async temAtribuicao(etapa, tipo, usuario){
    if(etapa && tipo && usuario){
      return Http.get(`${path}/tem-atribuicao/${etapa}/${tipo}/${usuario}`);
    }
    return null;
  },

}
