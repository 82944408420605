<template>
  <card :titulo="nomeGuia" v-if="estadoPossuiGuia">
    <template #acoes>
      <a href class="icon mr-2" v-if="exibirDetalhesDajeBA && item.guia" @click.prevent.stop="detalhes(item)" title="Detalhes DAJE">
        <i class="fal fa-landmark"></i>
      </a>
      <a href class="icon" v-if="exibirDajeGeradoBA" @click.prevent.stop="dajesGerados(item.guia)" title="DAJES Gerados">
        <i class="fal fa-file-search"></i>
      </a>
    </template>
    <card-content class="mb-2" :background="true">
      <div class="row gutters-xs">

        <v-input type="mask" class="col" :error="validation.guia" :disabled="disabledGuia"
                 label="Número da Guia" v-model="item.guia"
                 :masks="mascaras"
                 @change="validarGuia()"/>

        <div class="w-100" />

        <v-input type="v-select" :options="tiposTribunal"
                 class="col-12" v-if="tiposTribunal.length" :disabled="!edicao"
                 label="Natureza" v-model="item.codigoTj" />

      </div>
    </card-content>

  </card>
</template>

<script>
import TipoServicoBusiness from "@/business/crud/TipoServicoBusiness.js";
import Card from "@/components/Layout/components/Card.vue";
import CardContent from "@/components/Layout/components/CardContent.vue";
import RegrasTribunalBusiness from "@/business/RegrasTribunalBusiness";
import FrontBusiness from "@/business/FrontBusiness";
import ModalDetalhesDaje from "@/components/Guia/ModalDetalhesDaje.vue";
import ModalDajesGeradosProtocolo from "@/components/Guia/ModalDajesGeradosProtocolo.vue";
import Utils from "@/commons/Utils";
import AtoBusiness from "@/business/protocolo/AtoBusiness";
import VueBusiness from "@/business/VueBusiness";
export default {
  name: "VinculoGuiaTribunal",
  components: {Card, CardContent},
  props:{
    item:Object,
    edicao:Boolean,
    dominio:{type: String, default: ""},
    tipo:String,
    validation:{type: Object, default: () => ({})},
  },
  data(){
    return {
      tiposTribunal: [],
    }
  },

  async created() {
    if(this.$root.isEstadoPE){
      let response = await TipoServicoBusiness.getTiposAtoTribunalTratado(this.item);
      this.tiposTribunal = response.tiposTribunal;
      this.item.codigoTj = response.item.codigoTj;
    }
  },

  computed: {

    disabledGuia() {
      if (!this.edicao) {
        return true;
      }
      if (this.item.selado) {
        if(this.$root.isEstadoPE){
          return !this.$root.getPermissionOrAssignment({id: 'PERMITIR_ALTERAR_SICASE_SELADO', protocolo: this.item.protocolo});
        }
        return true;
      }

      return false;
    },

    mascaras(){
      return RegrasTribunalBusiness.getMascarasGuiaTribunal();
    },

    estadoPossuiGuia(){
      return RegrasTribunalBusiness.exibirGuia();
    },

    isDaje() {
      return this.$root.isEstadoBA || this.$root.isEstadoSE
    },

    nomeGuia(){

      if(this.$root.isEstadoPE){
        return 'SICASE';
      }

      if(this.isDaje){
        return 'DAJE';
      }

    },

    tamanhoGuia(){
      return RegrasTribunalBusiness.getTamanhoGuiaTribunal();
    },

    VISUALIZAR_BOTAO_DETALHES_DAJE() {
      return this.$root.getPermission({id: 'VISUALIZAR_BOTAO_DETALHES_DAJE', dominio: this.protocolo?.dominio || 'PROTOCOLO_RI'});
    },

    VISUALIZAR_DAJE_GERADO() {
      let tipo = this.tipo === 'certidao' ? 'certidao' : 'protocolo';
      return this.$root.getPermission({id: `VISUALIZAR_DAJE_GERADO_${tipo}`, dominio: this.protocolo?.dominio || 'PROTOCOLO_RI'});
    },

    exibirDajeGeradoBA() {
      return this.$root.isEstadoBA && this.VISUALIZAR_DAJE_GERADO;
    },

    exibirDetalhesDajeBA() {
      return this.$root.isEstadoBA && this.VISUALIZAR_BOTAO_DETALHES_DAJE;
    }
  },

  methods:{
    async validarGuia(){
      let guia = this.item.guia;
      this.$set(this.validation, 'guia', null);
      if(guia.length < this.tamanhoGuia){
        this.$set(this.validation, 'guia', 'Número da Guia com quantidade de caracteres inválidos');
      } else {
        let registros = (await this.$api.get(`/api/v2/regras-estaduais/BA/informacoes-item-daje-duplicado/${guia}`)
          .catch( e => FrontBusiness.showError('', e.message)) || []);
        if(!Utils.isEmptyNullOrUndefined(registros[0])){
          let inicioMensagem = 'DAJE '+guia+' já cadastrado';
          let finalMensagem = registros[0].dominio+'.';
          let mensagem ='';
          switch (registros[0].origem) {
            case 'CERTIDAO':
              let tipo = registros[0].tipo == 'ORCAMENTO' ? 'Orçamento' : 'Pedido';
              mensagem =  inicioMensagem+' na Certidão '+ FrontBusiness.formatarCodigo(registros[0].codigo) +', '+tipo+' de '+ registros[0].dominio +'.';
              break;
            case 'ATO':
              mensagem = inicioMensagem+' no ['+ registros[0].registro +'], Matrícula [M.'+registros[0].matricula+ '], '+ finalMensagem;
              break;
            case 'REGISTRO_AUXILIAR':
              mensagem = inicioMensagem+' no ['+ registros[0].registro +'], RA ['+registros[0].indicador+ '], '+finalMensagem;
              break;
            case 'CUSTA_ADICIONAL':
              mensagem = inicioMensagem+' na custa adicional, ' +finalMensagem;
              break;
          }
          this.$set(this.validation, 'guia', mensagem);
        }
      }
    },
    detalhes() {
      FrontBusiness.openModal(ModalDetalhesDaje, {item: this.item});
    },
    async dajesGerados() {
      let codigo = await FrontBusiness.openModal(ModalDajesGeradosProtocolo, {protocolo: this.item.protocolo.id });
      if (codigo) {
        this.item.guia = codigo;
        await this.validarGuia();
        this.$forceUpdate();
      }
    }
  }

}
</script>
