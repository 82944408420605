<script>

import EnderecoBusiness from "@/business/EnderecoBusiness";
import FrontBusiness from "@/business/FrontBusiness";
import IndicadorRealBusiness from "@/business/indicadores/IndicadorRealBusiness";
import Utils from "@/commons/Utils.js";
import ConfrontacaoIndicadorReal from "@/components/IndicadorReal/Form/ConfrontacaoIndicadorReal";
import DetalharAreasIndicadorReal from "@/components/IndicadorReal/Form/DetalharAreasIndicadorReal";
import RegistroAnteriorLista from "@/components/IndicadorReal/RegistroAnteriorLista"
import Card from "@/components/Layout/components/Card.vue";
import CardContent from "@/components/Layout/components/CardContent.vue";
import CardTable from "@/components/Layout/components/CardTable.vue";
import ReferenciaImovelLista from "@/components/IndicadorReal/ReferenciaImovelLista.vue";
import Modal from "@/components/Modal.vue";
import DetalhesRegistroAuxiliar from "@/components/RegistroAuxiliar/Detalhes/DetalhesRegistroAuxiliar.vue";

export default {

        name: "EditarIndicadorReal",
        props: ['ato', 'edicao', 'ficha'],

        components: {
            ReferenciaImovelLista,
            Modal,
            CardTable,
            Card,
            CardContent,
            DetalharAreasIndicadorReal,
            ConfrontacaoIndicadorReal,
            RegistroAnteriorLista
        },

        mounted() {
          this.loadCampos();
        },

        watch: {
          'form.campo': function () {
            this.showInputReload = false;
            this.form.valor = null;
            setTimeout(() => this.showInputReload = true);
          },
          'ato.dto.indicador.imovelUniao': function () {
            this.loadCampos();
          }
        },

        computed: {
            camposEditados() {
              return Object.keys(this.ato?.dto?.indicador || {});
            },
            optionsCampos() {
                let opcoes = this.options.campos;
                if (!this.ficha.unidadeAutonoma) {
                    opcoes = opcoes.filter(function (obj) {
                        return obj.id !== 'camposAreaComum';
                    });
                }

                let indicadores = this.camposEditados;
                if (indicadores.length > 0) {
                    opcoes = opcoes.filter(function (obj) {
                        return !indicadores.includes(obj.id);
                    });
                }

                return opcoes;
            },

            unidadeArea(){
                let medidaArea = this.form.medidaArea || this.ato?.dto?.indicador?.camposArea?.medidaArea || '';
                return medidaArea ? 'Fração solo (' + FrontBusiness.getLabel(medidaArea, IndicadorRealBusiness.getMedidas()) + ')' : 'Fração solo';
            },

            livroSelecionado() {
              return this.buscar === 'PRE_REGISTRO_AUXILIAR' ? 'REGISTRO_AUXILIAR' : this.buscar;
            },

            statusSelecionado() {
              return this.buscar === 'PRE_REGISTRO_AUXILIAR' ? 'PENDENTE' : 'ABERTA';
            },
        },

        data() {
            return {
                form: {},
                registroSelecionado: {},
                decimais: 10,
                buscar : 'REGISTRO_AUXILIAR',
                options: {
                    medidas: IndicadorRealBusiness.getMedidas(),
                    tipos: IndicadorRealBusiness.getTiposImovel(),
                    categorias: IndicadorRealBusiness.getCategorias(),
                    registroAnterior: [
                        {id: 'MATRICULA', nome: 'Matrícula'},
                        {id: 'TRANSCRICAO', nome: 'Transcrição'}
                    ],
                    estados: EnderecoBusiness.getEstados(),
                    campos: [],
                    livros: [
                      {id: 'REGISTRO_AUXILIAR', nome: 'Registro Auxiliar'},
                      {id: 'PRE_REGISTRO_AUXILIAR', nome: 'Pré-Registro Auxiliar'},
                    ],
                },

                registrosVinculados: JSON.stringify(this.ficha.registrosVinculados),
                referencias: JSON.stringify(this.ficha.referencias),
                camposTipoImovel: [],
                camposInfoComplementar: [],
                camposCadastroIdentificao: [],
                camposEndereco: [],
                camposArea: [],
                camposEspecificacao: [],
                camposAreaEdificada: JSON.stringify(this.ficha.areasEdificadas),
                camposAreaComum: JSON.stringify(this.ficha.areas),
                camposOrigens: JSON.stringify(this.ficha.origens),
                camposUnidadesAcessorias: JSON.stringify(this.ficha.unidadesAcessorias),
                camposConfrontacoes: JSON.stringify(this.ficha.confrontacoes),
                showInputReload: false

            }
        },

        methods: {
          montarOptions(atual) {
            let cidades = Utils.clone(this.$root.cidades);
            if(atual && !cidades.find(m => m.id === atual)){
              cidades.push({
                id: atual,
                nome: atual
              });
            }
            return cidades;
          },

          loadCampos() {
              this.camposInfoComplementar = IndicadorRealBusiness.getCamposInfoComplementar(this.ficha.categoria, this.ficha.unidadeAutonoma);
              this.camposCadastroIdentificao = IndicadorRealBusiness.getCamposCadastroIdentificacao(this.ficha.categoria, this.ficha.imovelUniao || this.ato.dto?.indicador?.imovelUniao);
              this.camposEndereco = IndicadorRealBusiness.getCamposEndereco();
              this.camposArea = IndicadorRealBusiness.getCamposArea(this.ficha.unidadeAutonoma);
              this.camposEspecificacao = IndicadorRealBusiness.getCamposEspecificao();
              this.options.campos = Utils.orderBy([
                {id: 'registrosVinculados',  nome: 'Registros Auxiliares Relacionados'},
                {id: 'certidao', tipo: 'editor', nome: 'Observações para Certidão'},
                {id: 'observacao', tipo: 'editor', nome: 'Observações Internas'},
                {id: 'referencias', nome: 'Referências'},
                {id: 'camposTipoImovel', nome: 'Tipo de imóvel'},
                {id: 'imovelUniao', tipo: 'radio', nome: 'Imóvel da União'},
                {id: 'marinha', tipo: 'radio', nome: 'Imóvel da Marinha'},
                {id: 'fronteira', tipo: 'radio', nome: 'Imóvel de Fronteira'},
                ...this.camposInfoComplementar,
                ...this.camposCadastroIdentificao,
                ...this.camposEndereco,
                {id: 'camposArea', nome: 'Área'},
                {id: 'camposAreaEdificada', nome: 'Áreas Edificadas'},
                {id: 'camposAreaComum', nome: 'Outras Áreas'},
                {id: 'camposUnidadesAcessorias', nome: 'Unidades Acessórias'},
                {id: 'camposConfrontacoes', nome: 'Confrontações'},
                ...this.camposEspecificacao,
                {id: 'registroAnterior', nome: 'Registro Anterior'},
              ], 'nome');

              if (!this.ficha.unidadeAutonoma) {
                this.options.campos = this.options.campos.filter(function (obj) {
                  return obj.id !== 'camposAreaComum';
                });
              }

              this.$set(this.form, 'camposAreaEdificada', JSON.parse(this.camposAreaEdificada) || [])
              this.$set(this.form, 'camposAreaComum', JSON.parse(this.camposAreaComum) || [])
              this.$set(this.form, 'origens', JSON.parse(this.camposOrigens) || [])
              this.$set(this.form, 'camposUnidadesAcessorias', JSON.parse(this.camposUnidadesAcessorias) || [])
              this.$set(this.form, 'camposConfrontacoes', this.camposConfrontacoes ? JSON.parse(this.camposConfrontacoes) || [] : [])
              this.$set(this.form, 'referencias', JSON.parse(this.referencias) || [])
              this.$set(this.form, 'registrosVinculados', JSON.parse(this.registrosVinculados) || [])
              //this.$set(this.form, 'camposTipoImovel', JSON.parse(this.camposTipoImovel) || [])

              const indicadores = Object.assign({}, this.ato.dto.indicador);
              for (let s in indicadores) {
                let id = (this.options.campos.find(c => c.id == s) || {}).id
                if(!id) {
                  delete indicadores[s];
                }
              }
              this.ato.dto.indicador = indicadores;
            },

            name(id) {
              return (this.options.campos.find(c => c.id == id) || {}).nome;
            },

            type(id) {
                return (this.options.campos.find(c => c.id == id) || {}).tipo;
            },

            add(e) {
                this.$nextTick(() => {
                    const indicadores = Object.assign({}, this.ato.dto.indicador);
                    if (this.form.campo == 'camposTipoImovel') {
                      indicadores['camposTipoImovel'] = {};
                      this.$set(indicadores['camposTipoImovel'], 'tipoImovel', this.form['tipoImovel']);
                      if(this.form['tipoImovel'] === 'OUTROS'){
                        this.$set(indicadores['camposTipoImovel'], 'descricaoTipoImovelOutros', this.form['descricaoTipoImovelOutros']);
                      } else {
                        this.$set(indicadores['camposTipoImovel'], 'descricaoTipoImovelOutros', "");
                      }
                    }
                    if (this.form.campo == 'camposArea') {
                        indicadores['camposArea'] = {};
                        for (let i = 0; i < this.camposArea.length; i++) {
                            if (this.form[this.camposArea[i].id]) {
                                this.$set(indicadores['camposArea'], this.camposArea[i].id, this.form[this.camposArea[i].id]);
                            }
                        }
                    } else if (this.form.campo == 'camposAreaEdificada') {
                        if (this.form['camposAreaEdificada'].length) this.$set(indicadores, 'camposAreaEdificada', this.form['camposAreaEdificada']);
                    } else if (this.form.campo == 'camposAreaComum') {
                        if (this.form['camposAreaComum'].length) this.$set(indicadores, 'camposAreaComum', this.form['camposAreaComum']);
                    } else if (this.form.campo == 'camposUnidadesAcessorias') {
                      if (this.form['camposUnidadesAcessorias'].length) this.$set(indicadores, 'camposUnidadesAcessorias', this.form['camposUnidadesAcessorias']);
                    } else if (this.form.campo == 'camposConfrontacoes') {
                      if (this.form['camposConfrontacoes'].length) this.$set(indicadores, 'camposConfrontacoes', this.form['camposConfrontacoes']);
                    } else if(this.form.campo ==  'registroAnterior'){
                      if (this.form['origens'].length) this.$set(indicadores, 'registroAnterior', this.form['origens']);
                    } else if(this.form.campo ==  'referencias'){
                      if (this.form['referencias'].length) this.$set(indicadores, 'referencias', this.form['referencias']);
                    } else if(this.form.campo ==  'registrosVinculados'){
                      if (this.form['registrosVinculados'].length) this.$set(indicadores, 'registrosVinculados', this.form['registrosVinculados']);
                    } else if(['imovelUniao', 'marinha', 'fronteira'].includes(this.form.campo)){
                      if (this.form.valor !== null) this.$set(indicadores, this.form.campo, this.form.valor);
                    } else if (this.form.campo && this.form.valor) {
                        this.$set(indicadores, this.form.campo, this.form.valor);
                    }

                    this.ato.dto.indicador = indicadores;
                    this.limparFormIndicadorReal();
                    this.$emit('change-editor', e, 'CAMPO_INDICADOR_REAL');
                });
            },

            limparFormIndicadorReal() {
                this.label = null;
                this.$set(this, 'form', {})
                this.$set(this.form, 'camposAreaEdificada', JSON.parse(this.camposAreaEdificada) || [])
                this.$set(this.form, 'camposAreaComum', JSON.parse(this.camposAreaComum) || [])
                this.$set(this.form, 'origens', JSON.parse(this.camposOrigens) || [])
                this.$set(this.form, 'camposUnidadesAcessorias', JSON.parse(this.camposUnidadesAcessorias) || [])
                this.$set(this.form, 'camposConfrontacoes', this.camposConfrontacoes ? JSON.parse(this.camposConfrontacoes) || [] : [])
                this.$set(this.form, 'registrosVinculados', JSON.parse(this.registrosVinculados) || [])
            },

            remove(key) {
                const indicadores = Object.assign({}, this.ato.dto.indicador);
                delete indicadores[key];
                this.ato.dto.indicador = indicadores;
            },

            isArea(campo) {
                return campo.startsWith('area') || campo.startsWith('fracao');
            },

            validarFracao(campo) {
                if (campo == 'fracaoSoloPercentual' && campo.valor > 100) {
                    this.$set(campo, 'valor', 100);
                }
            },

          nomearLivro: FrontBusiness.nomearFicha,

          detalhesFicha(id) {
            FrontBusiness.openModal(DetalhesRegistroAuxiliar, {id});
          },

          remover(idx) {
            this.$nextTick(() => {
              this.form.registrosVinculados.splice(idx, 1)
            });
          },

          selecionar(itens) {
            this.$nextTick(() => {
              this.$set(this.form, 'registrosVinculados', itens);
            });
          },

            ordenar(tipo, lista) {
              let campo = ''
              if (tipo === 'areasEdificadas') campo = 'camposAreaEdificada';
              if (tipo === 'areas') campo = 'camposAreaComum';
              if (tipo === 'unidadesAcessorias') campo = 'camposUnidadesAcessorias';
              if (tipo === 'confrontacoes') campo = 'camposConfrontacoes';
              this.$nextTick(() => {
                this.$set(this.form, campo, lista);
              })
            },

        }

    }
</script>

<template>

  <card titulo="Editar Indicador Real" id="editor-indicador-real">

    <card-table class="table-borderless">

      <thead>
      <tr>
        <th width="200px">Campo</th>
        <th class="pr-0">Valor</th>
        <th width="20px"></th>
      </tr>
      </thead>

      <tbody>
      <tr v-for="campo in camposEditados" :key="campo">
        <td>{{name(campo)}}</td>

        <td class="pr-0">
          <div class="row" v-if="campo == 'camposTipoImovel'">
            <v-input v-model="ato.dto.indicador.camposTipoImovel.tipoImovel" :options="options.tipos"
                     type="select" class="col-5" />
            <v-input class="col-7" v-model="ato.dto.indicador.camposTipoImovel.descricaoTipoImovelOutros" v-if="ato.dto.indicador.camposTipoImovel.tipoImovel == 'OUTROS'"
                     type="text" placeholder="Descrição do Tipo de Imóvel" />
          </div>

          <select :disabled="!edicao" v-else-if="campo == 'estado'" v-model="ato.dto.indicador[campo]"
                  class="form-control form-control-xs">
            <option v-for="(estado, index) in options.estados" :value="estado.id" :key="'estado'+index">
              {{estado.id}}
            </option>
          </select>

          <select :disabled="!edicao" v-else-if="campo == 'cidade'" v-model="ato.dto.indicador[campo]"
                  class="form-control form-control-xs">
            <option v-for="(cidade, index) in montarOptions(ato.dto.indicador[campo])" :value="cidade.id" :key="'cidade'+index">
              {{cidade.id}}
            </option>
          </select>

          <template v-else-if="campo == 'registroAnterior'">
            <card-content class="row gutters-xs content-ranterior tbl-visualizacao" style="padding: 0 !important;">
              <RegistroAnteriorLista :registros="ato.dto.indicador.registroAnterior" :visualizar="true" :isImportarRegistroAnterior="false" />
            </card-content>
          </template>

          <template v-else-if="campo === 'registrosVinculados'">
            <card>
              <card-table>
                <tbody>
                <tr v-for="(ficha, i) in ato.dto.indicador.registrosVinculados" :key="ficha.id">
                  <td class="pointer icon" @click="detalhesFicha(ficha.id)">
                    <a>{{nomearLivro(ficha)}}</a>
                  </td>
                </tr>
                </tbody>

              </card-table>
            </card>
          </template>

          <template v-else-if="campo == 'referencias'">

            <referencia-imovel-lista :ficha="ato.dto.indicador" />
          </template>
          <template v-else-if="campo =='camposArea'">

            <card-content class="row gutters-xs" style="padding: 0 !important;">

              <v-input :disabled="!edicao" class="col" v-model="ato.dto.indicador[campo].medidaArea" :options="options.medidas"
                       type="select" label="Medida Área"/>
              <v-input :disabled="!edicao" class="col" label="Área Total" v-model="ato.dto.indicador[campo].area" type="currency"
                       :input-props="{precision : {min : 2, max : decimais}}"/>

              <template v-if="ficha.unidadeAutonoma">

                <div class="w-100 mt-3"></div>

                <v-input :disabled="!edicao" class="col" :label="unidadeArea" v-model="ato.dto.indicador[campo].fracaoSolo" type="currency"
                         :input-props="{precision : {min : 2, max : decimais}}"/>
                <v-input :disabled="!edicao" class="col" label="Fração Solo (%)" v-model="ato.dto.indicador[campo].fracaoSoloPercentual"
                         type="currency" :input-props="{precision : {min : 2, max : decimais}}"/>
                <div class="w-100"></div>
                <v-input :disabled="!edicao" class="col" label="Coeficiente de proporcionalidade" v-model="ato.dto.indicador[campo].coeficienteProporcionalidade"
                         type="currency" :input-props="{precision : {currencyDisplay: 'hidden', min : 0, max : decimais}}"/>

              </template>

            </card-content>

          </template>

          <template v-else-if="campo =='camposAreaEdificada'">

            <card-content class="row gutters-xs content-aedificada tbl-visualizacao" style="padding: 0 !important;">
              <detalhar-areas-indicador-real titulo="Áreas edificadas" tipo="areasEdificadas" :visualizar="true" :edicao="edicao"
                                             :areas="ato.dto.indicador.camposAreaEdificada" :possui-tipo="false"/>

            </card-content>

          </template>

          <template v-else-if="campo =='camposAreaComum'">

            <card-content class="row gutters-xs content-acomum tbl-visualizacao" style="padding: 0 !important;">
              <detalhar-areas-indicador-real titulo="Outras Áreas" tipo="areas" :areas="ato.dto.indicador.camposAreaComum" :edicao="edicao"
                                             :visualizar="true"/>
            </card-content>

          </template>

          <template v-else-if="campo =='camposUnidadesAcessorias'">

            <card-content class="row gutters-xs content-acomum tbl-visualizacao" style="padding: 0 !important;">
              <detalhar-areas-indicador-real tipo="unidadesAcessorias" titulo="Unidade acessória" :visualizar="true" :edicao="edicao"
                                             :possui-tipo="false" :areas="ato.dto.indicador.camposUnidadesAcessorias" />
            </card-content>

          </template>

          <template v-else-if="campo =='camposConfrontacoes'">

            <card-content class="row gutters-xs content-acomum tbl-visualizacao" style="padding: 0 !important;">
              <confrontacao-indicador-real :edicao="edicao" :visualizar="true" :confrontacoes="ato.dto.indicador.camposConfrontacoes" />
            </card-content>

          </template>

          <v-input :disabled="!edicao" v-else-if="isArea(campo)" type="currency" :precision="10"
                   v-model="ato.dto.indicador[campo]" @change="validarFracao(campo, $event)"/>

          <v-input :disabled="!edicao" :small="true" v-else-if="campo == 'cep'" :type="'cep'" v-model="ato.dto.indicador[campo]"
                   :placeholder="name(campo)"/>

          <v-input :disabled="!edicao" class="mb-0" :small="true" v-else-if="campo =='cadastroImobiliarioBrasileiro'"
                   type="text" :inputProps="{ 'maxlength': 8}" v-model="ato.dto.indicador[campo]"
                   :placeholder="name(campo)"/>

          <v-input :disabled="!edicao" :small="true" v-else :type="type(campo)" v-model="ato.dto.indicador[campo]"
                   :placeholder="name(campo)" :masks="['#### #######-##']"/>
        </td>

        <td class="text-right">
          <div class="ato-action" v-if="edicao">
            <a href="" @click.prevent="remove(campo)" class="icon"><i class="fa fa-times"></i></a>
          </div>
        </td>

      </tr>


      <tr v-if="!camposEditados || !camposEditados.length">
        <td colspan="100%" class="p-0">
          <div class="alert alert-outline-secondary alert-sm mb-0">
            Nenhum campo será alterado
          </div>
        </td>
      </tr>

      </tbody>

      <tfoot v-if="edicao">
      <tr>

        <td class="pl-0" style="vertical-align: top;">
          <v-input  :disabled="!edicao" class="mb-0" v-model="form.campo"
                   :small="true" type="select" :options="optionsCampos"></v-input>
        </td>

        <td class="px-0">
            <card v-if="form.campo === 'registrosVinculados'" titulo="Registros Auxiliares Relacionados">
              <card-table >
                <thead>
                <tr>
                  <th>Registro Auxiliar</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(ficha, i) in form.registrosVinculados" :key="ficha.id">
                  <td class="pointer icon" @click="detalhesFicha(ficha.id)">
                    <a>{{nomearLivro(ficha)}}</a>
                  </td>
                  <td style="width: 370px">
                    <a @click.prevent="remover(i)" class="icon"><i class="fa fa-times"></i></a>
                  </td>
                </tr>
                <tr>
                  <td class="pr-1 py-1 pb-2 pt-2">
                    <div class="row m-0" >
                      <v-input class="col-auto p-0" v-model="buscar" :options="options.livros" type="select" />
                      <buscar-fichas :livro="livroSelecionado" :status="statusSelecionado" ref="buscarFichas" @selecionar="selecionar($event)" class="pl-2 col"/>
                    </div>
                  </td>
                </tr>
                </tbody>

              </card-table>
            </card>

          <div class="row" v-if="form.campo == 'camposTipoImovel'">
            <v-input v-model="form.tipoImovel" :options="options.tipos"
                     type="select" class="col-5" />

            <v-input class="col-7" v-model="form.descricaoTipoImovelOutros" v-if="form.tipoImovel == 'OUTROS'"
                     type="text" placeholder="Descrição do Tipo de Imóvel" />
          </div>
          <template v-else-if="form.campo == 'referencias'">
            <card-content style="padding: 0 !important;">
              <referencia-imovel-lista  :ficha="form" :visualizar="false"/>
            </card-content>
          </template>

          <select :disabled="!edicao" v-else-if="form.campo == 'estado'" v-model="form.valor"
                  class="form-control form-control-xs mb-0">
            <option v-for="(estado, index) in options.estados" :value="estado.id" :key="'estado'+index">
              {{estado.id}}
            </option>
          </select>

          <select :disabled="!edicao" v-else-if="form.campo == 'cidade'" v-model="form.valor"
                  class="form-control form-control-xs mb-0">
            <option v-for="(cidade, index) in $root.cidades" :value="cidade.id" :key="'cidade'+index">
              {{cidade.id}}
            </option>
          </select>

          <select :disabled="!edicao" v-else-if="form.campo == 'medidaArea'"
                  v-model="form.valor"
                  class="form-control form-control-xs mb-0">
            <option v-for="(medida, index) in options.medidas" :value="medida.id" :key="'medida'+index">
              {{medida.id}}
            </option>
          </select>

          <template v-else-if="form.campo == 'registroAnterior'">
            <RegistroAnteriorLista :registros="form.origens" @update="form.origens = $event" :isImportarRegistroAnterior="false" />
          </template>

          <template v-else-if="form.campo == 'camposArea'">
            <card class="m-0">
              <card-content class="row gutters-xs" style="padding: 0 !important;">
                <v-input class="col" v-model="form.medidaArea" :options="options.medidas" type="select"
                         label="Medida Área"/>
                <v-input class="col" label="Área Total" v-model="form.area" type="currency"
                         :input-props="{precision : {min : 2, max : decimais}}"/>

                <template v-if="ficha.unidadeAutonoma">

                  <div class="w-100 mt-3"></div>

                  <v-input class="col" :label="unidadeArea" v-model="form.fracaoSolo" type="currency"
                           :input-props="{precision : {min : 2, max : decimais}}"/>
                  <v-input class="col" label="Fração Solo (%)" v-model="form.fracaoSoloPercentual" type="currency"
                           :input-props="{precision : {min : 2, max : decimais}}"/>
                  <div class="w-100"></div>
                  <v-input class="col" label="Coeficiente de proporcionalidade" v-model="form.coeficienteProporcionalidade"
                           type="currency" :input-props="{precision : {currencyDisplay: 'hidden', min : 0, max : decimais}}"/>
                </template>
              </card-content>
            </card>
          </template>

          <template v-else-if="form.campo =='camposAreaEdificada'">
            <card class="m-0">
              <card-content class="row gutters-xs content-aedificada" style="padding: 0 !important;">
                <detalhar-areas-indicador-real :ordenar="true" @ordenar="ordenar" tipo="areasEdificadas" titulo="Áreas edificadas" :possui-tipo="false"
                                               :areas="form.camposAreaEdificada"/>
              </card-content>
            </card>
          </template>

          <template v-else-if="form.campo =='camposAreaComum'">
            <card class="m-0">
              <card-content class="row gutters-xs content-acomum" style="padding: 0 !important;">
                <detalhar-areas-indicador-real :ordenar="true" @ordenar="ordenar" tipo="areas" titulo="Detalhamento das áreas" :areas="form.camposAreaComum"/>
              </card-content>
            </card>
          </template>

          <template v-else-if="form.campo =='camposUnidadesAcessorias'">
            <card class="m-0">
              <card-content class="row gutters-xs content-acomum" style="padding: 0 !important;">
                <detalhar-areas-indicador-real :ordenar="true" @ordenar="ordenar" tipo="unidadesAcessorias" titulo="Unidade acessória" :areas="form.camposUnidadesAcessorias" />
              </card-content>
            </card>
          </template>

          <template v-else-if="form.campo =='camposConfrontacoes'">
            <card class="m-0">
              <card-content class="row gutters-xs content-acomum" style="padding: 0 !important;">
                <confrontacao-indicador-real :ordenar="true" @ordenar="ordenar" :confrontacoes="form.camposConfrontacoes"/>
              </card-content>
            </card>
          </template>

          <v-input :disabled="!edicao" class="mb-0" :small="true" v-else-if="form.campo =='cep'" :type="'cep'" v-model="form.valor"
                   :placeholder="name(form.campo)"/>

          <v-input :disabled="!edicao" class="mb-0" :small="true" v-else-if="form.campo =='cadastroImobiliarioBrasileiro'"
                   type="text"  :inputProps="{ 'maxlength': 8}" v-model="form.valor"
                   :placeholder="name(form.campo)"/>

          <template v-else>
            <v-input v-if="showInputReload" :disabled="!edicao" class="mb-0" :small="true"  :type="type(form.campo)" v-model="form.valor"
                     :placeholder="name(form.campo)" :masks="['#### #######-##']"/>
          </template>

        </td>

        <td class="text-right pr-0" style="vertical-align: top;">
          <div class="ato-action" v-if="form && form.campo">
            <a href="" @click.prevent="add($event)" class="btn btn-outline-secondary btn-xs">
              <i class="fa fa-plus"></i>
            </a>
          </div>
        </td>

      </tr>
      </tfoot>

    </card-table>

  </card>

</template>

<style lang=scss>
  #editar-indicador-real{
    #registro-anterior-wrapper{
      table {
        tbody {
          tr {
            td {
              padding-bottom: 0.75rem;
            }
          }
        }
      }
    }
  }
</style>
