<template>

  <card titulo="Documentos">

    <card-table class="table-borderless">
      <thead>
        <tr>
          <th class="pr-0" style="min-width: 100px;">Tipo</th>
          <th class="pr-0">Data</th>
          <th class="pr-0">Número</th>
          <th class="pr-0">Observações</th>
          <th class="pr-0" v-if="$root.config.livros.livroRegistroIntegral.controleNumFolhas">Quantidade de Folhas</th>
          <th class="pr-0" v-if="$root.config.livros.livroRegistroIntegral.controleNumFolhas && !['MT'].includes($root.config.estado)">Quantidade de Arquivamento</th>
          <th />
          <th width="20px">
            <a href="" @click.prevent="add()" class="btn btn-outline-secondary btn-xs" v-if="edicao">
              <i class="fa fa-plus"></i>
            </a>
          </th>
        </tr>
      </thead>

      <tbody>

        <tr v-for="(campo, idx) in ato.indicadorDocumentos" :key="idx">
          <td>
            <v-input :disabled="!edicao" v-model="campo.tipoDocumento" type="v-select" :options="documentos" />
          </td>
          <td>
            <v-input type="date" v-model="campo.dataDocumento" :disabled="!edicao"/>
          </td>
          <td>
            <v-input type="text" v-model="campo.numeroDocumento" :disabled="!edicao"/>
          </td>
          <td>
            <v-input type="text" v-model="campo.observacao" :disabled="!edicao"/>
          </td>
          <td v-if="$root.config.livros.livroRegistroIntegral.controleNumFolhas">
            <v-input type="number" v-model="campo.quantidadeFolhas" :disabled="!edicao" @input="atualizarArquivamento(idx)"
            />
          </td>
          <td v-if="$root.config.livros.livroRegistroIntegral.controleNumFolhas && !['MT'].includes($root.config.estado)">
            <v-input type="number" v-model="campo.quantidadeArquivamento" :disabled="!edicao" />
          </td>
          <td>
            <a @click="anexarDocumentos(campo.id)" title="Anexar Documentos" class="icon">
              <i class="fal fa-paperclip"></i>
            </a>
          </td>
          <td class="text-center">
            <div class="ato-action" v-if="edicao">
              <a href="" @click.prevent="remove(idx)" class="icon"><i class="fa fa-times"></i></a>
            </div>
          </td>
        </tr>

        <tr v-if="!ato.indicadorDocumentos || !ato.indicadorDocumentos.length">
          <td colspan="100%" class="p-0">
            <div class="alert alert-outline-secondary alert-sm mb-0">
              Nenhum documento cadastrado
            </div>
          </td>
        </tr>

      </tbody>

    </card-table>

  </card>

</template>

<script>
import Card from "@/components/Layout/components/Card.vue";
import CardTable from "@/components/Layout/components/CardTable.vue";
import TipoDocumentoBusiness from "@/business/crud/TipoDocumentoBusiness";
import IndicadorBusiness from "@/business/indicadores/IndicadorBusiness";
import FrontBusiness from "@/business/FrontBusiness";
import AnexosModal from "@/components/Anexos/AnexosModal.vue";
import IndicadorAbstractService from "@/business/indicadores/IndicadorAbstractService";
import Utils from "@/commons/Utils";

export default {

    name: "Documentos",
    components: {CardTable, Card},
    props: ['ato', 'edicao'],

    async created() {
      let atribuicao = IndicadorBusiness.getLivro(this.ato.livro)?.sigla;
      this.ato.indicadorDocumentos = this.ato.indicadorDocumentos || [];
      let idsTipo = [...new Set([])].filter(el => el != null);
      await TipoDocumentoBusiness.getSimpleListAssignmentIds(atribuicao, idsTipo).then(l => this.documentos = Object.freeze(l));
      this.loading = false;
    },

    data() {
      return {
        documentos: []
      }
    },

    methods: {
      async add() {
        let response = await IndicadorAbstractService.saveDocumento(this.ato.id, this.ato.indicador.id,  {tipoDocumento: {id: this.documentos?.[0]?.id},
          ordem: this.ato.documentos.length + 1,
          quantidadeFolhas: 0,
          quantidadeArquivamento: 0
        });
        if (response) {
          response.tipoDocumento = response.tipoDocumento?.id
          this.ato.indicadorDocumentos.push(response);
        }
        this.$forceUpdate();
      },
      async anexarDocumentos(id) {
        await FrontBusiness.openModal(AnexosModal, {id, entidade: "INDICADOR_DOCUMENTO", permitirEdicao: true});
      },
      remove(idx){
        this.ato.indicadorDocumentos.splice(idx, 1);
        this.$forceUpdate();
      },

      atualizarArquivamento(idx) {
        const documento = this.ato.indicadorDocumentos[idx];
        if (documento) {
          documento.quantidadeArquivamento = documento.quantidadeFolhas;
        }
      },
    },

  }
</script>

