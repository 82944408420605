import FrontBusiness from "@/business/FrontBusiness";
import IndicadorRealBusiness from "@/business/indicadores/IndicadorRealBusiness";
import RegistroAuxiliarBusiness from "@/business/indicadores/RegistroAuxiliarBusiness";
import IndisponibilidadeBusiness from "@/business/IndisponibilidadeBusiness.js";
import getVar from "@/business/livros/copiladores/getVar.js";
import indicadorPessoal from "@/business/livros/copiladores/indicadorPessoal.js";
import proxy from "@/business/livros/copiladores/proxy.js";
import tabelaSigef from "@/business/livros/copiladores/tabelaSigef.js";
import qualificarEnvolvido from "@/business/livros/utils/qualificarEnvolvido.js";
import AtoBusiness from "@/business/protocolo/AtoBusiness.js";
import ProtocoloFinanceiroBusiness from "@/business/protocolo/ProtocoloFinanceiroBusiness.js";
import RegraCustasBusiness from "@/business/RegraCustasBusiness.js";
import RegrasEstaduaisBusiness from "@/business/RegrasEstaduaisBusiness.js";
import SeloBusiness from "@/business/SeloBusiness.js";
import VueBusiness from "@/business/VueBusiness.js";
import Utils from "@/commons/Utils";
import moment from "moment";
import TextParser from './TextParser';
import extenso from 'extenso';

export default {

  async gerarMinutaAto(ato, ficha, protocolo, documentos, usuarioLogado = {}){

    let dicionario = await this.gerarDicionario(ato, ficha, protocolo, documentos);
    dicionario.usuarioLogado = { ...usuarioLogado, senha : undefined };

    /** CARREGA ENVOLVIDOS DO ATO **/
    let proprietarios = {};
    (ficha.proprietarios || []).forEach(p => {
      if(p?.indicadorPessoalVersao?.indicadorPessoalId){
        proprietarios[p.indicadorPessoalVersao.indicadorPessoalId] = parseFloat(p.fracao)
      }else{
        console.debug(p)
      }
    });

    let insideEnvolvidoTag = false, ignore = [];
    if(ato){
      await TextParser.tratarEnvolvidos(ato?.envolvidos, dicionario, proprietarios);
    }

    const cache = {};

    const callback = async (nodes = [], dicionario = {}, debug) => {

      let envolvidos = dicionario?.envolvidos?.lista || [];
      if(dicionario.vinculo?.envolvidos?.lista){
        envolvidos = dicionario.vinculo.envolvidos.lista;
      }

      let output = '';

      for(let pos in nodes){
        let node = nodes[pos];

        let debugNode = debug ? { tipo : node.name, nodes : [], params : node.params, custom : {} } : null;

        switch(node.name){

          // case 'taxa':{
          //
          //   if(dicionario.ato){
          //     output += FrontBusiness.formatNumber( dicionario.ato.taxas?.[node.params.tipo] || 0.0 , 2);
          //   }
          //
          //   break;
          // }

          case 'remover':

            //remover do loop caso o conjuge seja apresentado
            if(insideEnvolvidoTag && node.params.conjuge !== undefined){
              if (dicionario.envolvido?.conjuge) {
                ignore.push(dicionario.envolvido.conjuge?.indicadorPessoalId);
              }
            }

            if(insideEnvolvidoTag && node.params.convivente !== undefined){
              if (dicionario.envolvido?.convivente) {
                ignore.push(dicionario.envolvido.convivente?.indicadorPessoalId);
              }
            }

            break;

          case 'fracao':
            output += this.fracao(node, dicionario, envolvidos );
            break;

          case 'plural':
            output += this.plural(node, dicionario, envolvidos, ato, debugNode);
            break;

          case 'subgrupo':
            //FrontBusiness.showWarn(`Atenção: Utilização do método "subgrupo" na minuta não é mais recomendado. Favor avisar a coordenação.` );
            output += await this.agruparChecklist(dicionario, node, callback, debugNode);
            break;

          case 'checklist':
            //FrontBusiness.showWarn(`Atenção: Utilização do método "checklist" na minuta não é mais recomendado. Favor avisar a coordenação.` );
            output += await this.repetidorChecklist(node, dicionario, callback, debugNode);
            break;

          case 'documentos':
            const val = dicionario.checklist[node.params.indice];
            let documentos = (Array.isArray(val) ? val : [val]).filter(e => e);
            documentos = documentos.map(i => dicionario.documentos[i]).filter(e => e);
            output += await callback(node.children, {...dicionario, documentos}, debug);
            break;

          case 'recolhimentos':{
            output += await callback(node.children, dicionario, debug);
            break;
          }

          case 'custas-protocolo':
            if(!dicionario.protocolo.custas && dicionario.protocolo.id){
              dicionario.protocolo.custas = await ProtocoloFinanceiroBusiness.totalConsolidado(dicionario.protocolo.id);
            }
            output += await callback(node.children, dicionario, debug);
            break;

          case 'dajes':
            if(!dicionario.dajes){
              dicionario.dajes = await RegrasEstaduaisBusiness.capturarDajesAtoBA(ato.id);
            }
            output += await callback(node.children, dicionario, debug);
            break;

          /*******************************************************************************************/
          case 'condicao':{

            // VALIDAÇÃO DA QUANTIDADE DE RESTRIÇÕES
            if(node.params.se == 'restricoes.imovel'){
              if(!cache.restricoes){
                cache.restricoes = await IndicadorRealBusiness.restricoesVigentes(ficha.id);
              }

              let tipos = false;
              if(node.params.tipo !== undefined){
                tipos = node.params.tipo.split('|');
              }

              dicionario.restricoes = {...(dicionario.restricoes || {}), imovel : cache.restricoes.filter(e => !tipos || tipos.includes(e.restricao)).length }
            }

            output += await TextParser.commonsParse(node, dicionario, callback, debugNode);
            break;

          }

          case 'restricoes-canceladas' : {
            let ids = ato?.dto?.restricoesCanceladas || [];

            let restricoesCanceladas = await AtoBusiness.getRestricoesPorId(ato.id, ids);

            restricoesCanceladas = (restricoesCanceladas || []).map(restricao => {

              let ato = JSON.parse(restricao.descricaoRestricao);

              let codigo = ato.codigo || ato.codigoAto;
              if(!codigo){
                codigo = AtoBusiness.getCodigo(ato, ficha, ato.tipoAto, true);
              }
              return {
                ato: {
                  codigo: codigo,
                  tipo: ato.tipoAto == 'REGISTRO' ? 'R' : 'Av',
                  titulo: ato.opcoes.titulo,
                  checklist: ato.checklist,
                },
                pessoa: {
                  nome : restricao.nome,
                  documento : restricao.documento,
                  restricao : restricao.restricao,
                  papel : restricao.papel,
                },
              }
            });

            if(ato?.dto?.restricoesCanceladasSemRegistrar?.length){

              let method = ato.livro != 'REGISTRO_AUXILIAR' ? AtoBusiness.restricoesSemRegistrar : AtoBusiness.restricoesSemRegistrarRegistroAuxiliar;

              let restricoes = (await method(protocolo?.id, ato?.id, ficha?.id));

              const restricoesCanceladasSemRegistrar = this.ato.dto.restricoesCanceladasSemRegistrar;
              restricoes = (restricoes || []).filter(restricao => {
                return restricoesCanceladasSemRegistrar.filter(r => r.atoId === restricao.atoId
                  && r.restricao === restricao.restricao && r.indicadorPessoalVersaoId === restricao.indicadorPessoalVersaoId ).length > 0
              }).map(restricao => {

                let a = JSON.parse(restricao.descricaoRestricao);

                const codigo = AtoBusiness.gerarCodigoSimplificado(a?.ordem, ficha?.ctrl?.ultimoAto || 0, a?.tipoAto, ficha?.ctrl?.status);

                return {
                  ato: {
                    codigo: codigo,
                    tipo: a?.tipoAto == 'REGISTRO' ? 'R' : 'Av',
                    // titulo: ato.opcoes.titulo,
                    // checklist: ato.checklist,
                  },
                  pessoa: {
                    // nome : restricao.nome,
                    // documento : restricao.documento,
                    // restricao : restricao.restricao,
                    // papel : restricao.papel,
                  },
                }

              });

              restricoesCanceladas.push(...(restricoes || []));

            }

            output += await callback(node.children, {...dicionario, restricoesCanceladas}, debugNode);
            break;
          }

          case 'qualificacao': {

            let papel = node.params.papel, inserirConjugue = node.params.conjuge || true, outputEnvolvidos = [], pular = [];

            for(let eI in envolvidos){
              let envolvido = envolvidos[eI];

              if (!papel || envolvido.papel == papel) {

                if (pular.includes(envolvido.indicadorPessoalVersao.indicadorPessoalId)) {
                  continue;
                }

                if (envolvido.indicadorPessoalVersao.conjuge) {
                  let conjuge = envolvidos.find(e => e.indicadorPessoalVersao?.indicadorPessoalId == envolvido?.indicadorPessoalVersao.conjuge?.indicadorPessoalId);

                  if (conjuge) {
                    envolvido.conjuge = {...conjuge};
                    envolvido.indicadorPessoalVersao.conjuge = {...conjuge.indicadorPessoalVersao};
                    envolvido.conjugeId = conjuge.indicadorPessoalVersao.indicadorPessoalId;
                    if(inserirConjugue){
                      pular.push(envolvido.conjugeId);
                    }
                  }

                }

                outputEnvolvidos.push(await qualificarEnvolvido(envolvido.indicadorPessoalVersao, null, true, inserirConjugue));

              }
            }

            output += FrontBusiness.formatArray(outputEnvolvidos, node.params.separador || ', ', node.params.ultimo || ' e ');

            break;
          }

          case 'envolvidos' : {

            insideEnvolvidoTag = true, ignore = [];
            let papel = node.params.papel, outputEnvolvidos = [], lista = node.params.lista !== undefined, listagem = [];

            let i = 0;

            for(let eI in envolvidos){
              let envolvido = envolvidos[eI];

              if (!papel || envolvido.papel == papel) {

                if (ignore.includes(envolvido.indicadorPessoalVersao.indicadorPessoalId)) {
                  continue;
                }

                if (envolvido.indicadorPessoalVersao.conjuge) {
                  let conjuge = envolvidos.find(e => e.indicadorPessoalVersao?.indicadorPessoalId == envolvido?.indicadorPessoalVersao.conjuge?.indicadorPessoalId);

                  if (conjuge) {
                    envolvido.conjuge = {...conjuge};
                    envolvido.indicadorPessoalVersao.conjuge = {...conjuge.indicadorPessoalVersao};
                    envolvido.conjugeId = conjuge.indicadorPessoalVersao.indicadorPessoalId;
                  }

                }
                let envolvidoDicionario = await this.gerarDicionarioEnvolvido(envolvido, proprietarios, dicionario.checklist.envolvidos);
                envolvidoDicionario.posicao = ++i;

                if(lista){
                  listagem.push(envolvidoDicionario);
                }else{
                  dicionario.envolvido = envolvidoDicionario;
                  outputEnvolvidos.push(await callback(node.children, dicionario, debugNode));
                  dicionario.envolvido = null;
                }

              }
            }

            if(lista){
              output += await callback(node.children, {...dicionario, envolvidos: listagem}, debugNode);
            }else{
              output += FrontBusiness.formatArray(outputEnvolvidos, node.params.separador, node.params.ultimo);
            }
            insideEnvolvidoTag = false;
            break;

          }

          case 'tabela-sigef': {
            output += await tabelaSigef(ficha, node, callback, dicionario, debugNode);
            break;
          }

          /*******************************************************************************************/

          default:
            output += await TextParser.commonsParse(node, dicionario, callback, debugNode);
            break;
        }

        if(debugNode){
          debugNode.conteudo = TextParser.htmlText(node.body);
          if(debugNode.tipo || debugNode.conteudo){
            debug?.nodes.push({...debugNode});
          }
        }

      }

      return output;
    };

    await TextParser.init();
    let texto = await TextParser.parse(ato.templateMinuta || ato.tipoServico.minuta, callback, dicionario);

    return TextParser.clean(texto + '.');

  },

  async gerarDicionario(ato, ficha, protocolo = {}, documentos = [], simplificado = false){
    let docs = {};
    documentos.forEach(d => {
      docs[d.id] = {...d.indices, tipoDocumento : d.tipo, tituloDocumento: d.nome}
    });

    const dicionario = {
      protocolo : {},
      imovel : {},
      registro : {},
      checklist : {},
      documentos : docs
    };

    /**
     * CONFIGURACOES DO PROTOCOLO
     */
    if (protocolo?.id != null) {

      dicionario.protocolo = {
        id : protocolo.id,
        codigo : protocolo.codigo,
        dataCadastro : protocolo.cadastro,
        dataReingresso : protocolo.reingresso,
        dataRegistro : protocolo.registro ? protocolo.registro : moment(),
        tipo : protocolo.tipoProtocolo,
        tipoRegistro : protocolo.tipoRegistro,
        registrado: !!protocolo.registro,
        protocoloExterno: protocolo.protocoloExterno,
        identificacaoEstadual: protocolo.identificacaoEstadual,
        dadosImportados: protocolo.dadosImportados
      };

      if(protocolo?.tabelionato?.id){
        dicionario.protocolo.tabelionato = {
          estado: protocolo?.tabelionato.estado,
          telefone: protocolo?.tabelionato.telefone,
          emails: protocolo?.tabelionato.emails,
          nome: protocolo?.tabelionato.nome
        }
      }

      if(!simplificado){
        dicionario.protocolo.seloPrincipal = protocolo?.seloPrincipal || await SeloBusiness.getSeloProtocolo(protocolo.id);
        dicionario.protocolo.selo = dicionario.protocolo?.seloPrincipal?.codigo;
      }

      /**
       * CONFIGURACOES DA INDISPONIBILIDADE
       */
      if(protocolo?.indisponibilidade?.id && !simplificado){

        let indisponibilidade = protocolo?.indisponibilidade;
        if(!protocolo?.indisponibilidade?.protocoloCnib){
          indisponibilidade = await IndisponibilidadeBusiness.getResumoById(protocolo.indisponibilidade?.id);
        }

        //protocolo, data do protocolo da cnib, numero do processo, campo juizo
        dicionario.protocolo.indisponibilidade = {
          data : (indisponibilidade.pedido || ''),
          pedido : (indisponibilidade.pedido || ''),
          cancelamento : (indisponibilidade.cancelamento || ''),
          protocoloCnib : indisponibilidade.protocoloCnib,
          protocoloCnibCancelado : indisponibilidade.protocoloCnibCancelado,
          numeroProcesso : indisponibilidade.numeroProcesso,
          instituicao : indisponibilidade.instituicao,
          forumVara : indisponibilidade.forumVara,
          juizo : indisponibilidade.juizo,
          solicitante : indisponibilidade.solicitante
        }
      }
      //dicionario['protocolo.codigo'] = MinutaParser.formatarCodigo(protocolo.codigo);
    }

    if(ato?.id){

      /**
       * CONFIGURACOES DO ATO
       */
      dicionario.ato = {
        registrado: !!protocolo.registro,
        id : ato.id,
        codigo : (ato.codigo || ato.codigoAto || '{{ATO}}') + '', //AtoBusiness.getCodigo(ato, ficha)))
        tipo : ato.tipoServico.opcoes.tipoAto == 'REGISTRO' ? 'R' : 'Av',
        tipoRegistro: ato.tipoRegistro,
        selo: '<span class="text-info-soft">{{SELOS}}</span>',
        selos: '<span class="text-info-soft">{{SELOS}}</span>',
        isento : ato.isento,
        valor : ato.valor,
        guia : ato.guia,
        codigoFiscal : ato.codigoFiscal,

        diferido : ato.diferido || false,
        motivoDiferimento : ato.motivoDiferimento,

        titulo : ato.tipoServico.opcoes.titulo,
        nomenclatura : ato.tipoServico.nome,
        numeroCat : ato.numeroCat,

        numeroControleLivro: ato.numeroControleLivro,
        numeroControleFolha: ato.numeroControleFolha,
        quantidadeFolhas: ato.quantidadeFolhas,
        numeroUltimaFolha: ato.numeroControleLivro && ato.numeroControleFolha ? ato.numeroControleFolhaFinal || (ato.numeroControleFolha + ato.quantidadeFolhas - 1) : null,

        doi : {},
        valores : {},

        ficha : {...ficha, usuarioImpressao : undefined, usuarioEncerramento: undefined},
        dto : ato.dto,
        moeda : {
          alienacao : ato.dto?.camposAdicionais?.MOEDA_ALIENACAO,
          avaliacao : ato.dto?.camposAdicionais?.MOEDA_AVALIACAO,
          divida : ato.dto?.camposAdicionais?.MOEDA_DIVIDA,
        },
      };

      if (window.server.config.livros.livroRegistroIntegral.cobrarCustaArquivamentoPagina) {
        dicionario.ato.numeroControleFolha += (ato.ladoPaginaControleInicial || '');
        if (ato.numeroControleFolhaFinal) {
          dicionario.ato.numeroUltimaFolha = (ato.numeroControleFolhaFinal || '') + (ato.ladoPaginaControleFinal || '');
        } else {
          dicionario.ato.numeroUltimaFolha = ato.numeroControleLivro && ato.numeroControleFolha ? ato.numeroControleFolha + (ato.quantidadeFolhas / 2) - 1 : null;
        }
      }

      dicionario.ato.dto.camposAdicionais.TIPO_REGISTRO = ato.tipoRegistro;

      if(!protocolo.registro){
        dicionario.ato.codigo = '{{ATO}}';
      }

      if(dicionario.ato.ficha.status == 'PENDENTE'){
        dicionario.ato.ficha.codigo = '<span class="text-info-soft">{{MATRICULA}}</span>'
      }else{
        dicionario.ato.ficha.codigo = dicionario.ato.ficha.codigo
      }

      if (ato.doi.gerarDoi) {
        dicionario.ato.doi = {
          situacaoDaConstrucao : FrontBusiness.getLabel(ato.doi.situacaoConstrucao, IndicadorRealBusiness.getSituacoesDoi()).toLowerCase(),
          formaDeAlienacaoAquisicao : FrontBusiness.getLabel(ato.doi.formaAlienacao, IndicadorRealBusiness.getFormasDoi()).toLowerCase(),
          dataDaAlienacao : ato.doi.dataAlienacao,
        };
      }

      if (ato.dto.valoresBaseCalculo) {
        Object.keys(ato.dto.valoresBaseCalculo).forEach(k => dicionario.ato[k] = ato.dto.valoresBaseCalculo[k]);
        dicionario.ato.valorAlienacao = ato.dto.valoresBaseCalculo.alienacao;
        dicionario.ato.valorAlienacaoAtualizado = ato.dto.valoresBaseCalculo.alienacaoAtualizado;
      }

      if (ato.ressarcimento) {
        dicionario.ato.ressarcimento = {
          motivoIsencao: FrontBusiness.getLabel(ato.ressarcimento?.motivoIsencao, RegraCustasBusiness.getMotivosIsencao(window.server.config?.estado))?.toLowerCase(),
          motivoIsencaoId: ato.ressarcimento?.motivoIsencao?.split('_')?.[1],
          tipoProcesso: FrontBusiness.getLabel(ato.ressarcimento?.tipoProcesso, RegraCustasBusiness.getTiposProcessoRessarcimento(window.server.config?.estado))?.toLowerCase(),
          tipoProcessoId: ato.ressarcimento?.tipoProcesso?.split('_')?.[1],
          numeroProcesso: ato.ressarcimento?.numeroProcesso
        }
      }

      dicionario.ato.frj = [];
      if(ato.recolhimentos){
        if(typeof ato.recolhimentos === 'string'){
          ato.recolhimentos = JSON.parse(ato.recolhimentos || '[]');
        }
        dicionario.ato.recolhimentos = ato?.recolhimentos || [];
        ato?.recolhimentos?.forEach(r => {
          if(r.tipo == 'FRJ'){
            dicionario.ato.frj.push(r);
          }
        });
      }

      // GERA AS TAXAS DO PROTOCOLO:
      let custas = ato.custas;
      if(ato.dto?.camposAdicionais?.ABATER_PRENOTACAO && VueBusiness.getVueRoot().isEstadoRO) {
        // capturar a custa de abatimento;
        const abatimentos = await RegrasEstaduaisBusiness.getCustaAbatimentoRO(protocolo.id).catch(e => ([]));
        if (abatimentos?.length) {
          custas = [...Object.values(custas), ...Object.values(abatimentos?.[0]?.custas)];
        }
      }

      const obj = FrontBusiness.somarTaxas(custas);
      obj.totalSemIss = obj.iss > 0 && obj.total > 0 ? FrontBusiness.twoDecimals((obj.total || 0) - obj.iss) : obj.total;
      Object.values(ato.custas || {}).forEach(custa => {
        obj.vrc = obj.vrc || custa.vrc;
      });

      dicionario.ato = {...dicionario.ato, ...obj};

      //livro estrangeiro
      if(AtoBusiness.mostrarCampoLivroEstrangeiro(ato)){
        dicionario.ato.livroEstrangeiro = {
          codigo: '{{LIVRO_ESTRANGEIRO}}',
          observacao : ato.observacaoLivroEstrangeiro
        }
      }

    }

    /**
     * Imovel
     */
    if (ficha?.id && ficha.tipoImovel) {

      dicionario.imovel = await this.gerarDicionarioImovel(ficha, simplificado);
      dicionario.imovel.novo = {};

      if(ato?.id){
        dicionario.ato.ficha.codigo = dicionario.imovel.codigo;
        Object.keys(ato.dto.indicador || {}).forEach(campo => {
          dicionario.imovel.novo[campo] = ato.dto?.indicador?.[campo];
        });
      }

    }

    /**
     * registro auxiliar
     */
    if (ficha?.id && (ficha.tipoRegistro || ficha.livro == 'REGISTRO_AUXILIAR' )) {

      dicionario.registro = await this.gerarDicionarioRegistroAuxiliar(ficha, simplificado);

      if(ato?.id){
        dicionario.ato.ficha.codigo = dicionario.registro.codigo;

        dicionario.registro.novo = {
          tipoCedula : ato.dto.tipoCedula,
          condominio : ato.dto.condominio,
          pacto : ato.dto.pacto,
          vencimentoCedula : ato.dto.vencimento,
          valorCedula : ato.dto.valoresBaseCalculo && ato.dto.valoresBaseCalculo.valorRegistro ? ato.dto.valoresBaseCalculo.valorRegistro: null,
          texto : ato.dto.texto,
          garantia : ato.dto.texto
        };

        if(dicionario.registro.novo.tipoCedula){
          dicionario.registro.novo.tipoCedula = FrontBusiness.getLabel(dicionario.registro.novo.tipoCedula, RegistroAuxiliarBusiness.getTiposCedulas()).toLowerCase();
        }

      }

      dicionario.registroAuxiliar = dicionario.registro;

    }

    dicionario.checklist = Utils.merge({}, dicionario?.checklist || {}, protocolo?.indices || {}, ato?.indices || {});

    return dicionario;

  },

  async gerarDicionarioImovel(ficha, simplificado= false){

    let imovel = {...ficha, usuarioImpressao : undefined, usuarioEncerramento: undefined};
    imovel.categoria = FrontBusiness.getLabel(ficha.categoria, IndicadorRealBusiness.getCategorias()).toLowerCase();
    imovel.tipoImovel = FrontBusiness.getLabel(ficha.tipoImovel, IndicadorRealBusiness.getTiposImovel()).toLowerCase();

    if(imovel.status == 'PENDENTE' || imovel.prematricula){
      imovel.codigo = FrontBusiness.formatarCodigoPreMatricula(imovel.codigo, {status : 'PENDENTE', id : imovel.id})
    }

    if(imovel.origens?.length){
      for(let origem of imovel.origens){
        if(origem.tipo == 'PRE_MATRICULA'){
          origem.codigo = FrontBusiness.formatarCodigoPreMatricula(origem.codigo||origem.registro, {
            status : 'PENDENTE',
            id : origem.origemId,
            codigo: origem.codigo||origem.registro,
            livro: 'MATRICULA'
          })
        }
      }
    }

    imovel.tipoImovelDescricao = imovel.descricaoTipoImovelOutros;
    imovel.areaTotal = imovel?.areas?.reduce((acc, cur) => acc + cur.area, 0.00);
    imovel.areaEdificadaTotal = imovel?.areasEdificadas?.reduce((acc, cur) => acc + cur.area, 0.00);

    if(ficha?.proprietarios?.length && !simplificado){

      let envolvidos = [], proprietarios = {};
      for(let eI in ficha.proprietarios){
        let pessoa = await this.gerarDicionarioEnvolvido({...ficha.proprietarios[eI]}, proprietarios);
        pessoa.indicadorPessoalVersao = {...ficha.proprietarios[eI].indicadorPessoalVersao};
        envolvidos.push(pessoa);
      }
      imovel.proprietarios = envolvidos;

    }

    return imovel;
  },

  async gerarDicionarioRegistroAuxiliar(ficha, simplificado = false){

    let registro = {...ficha, usuarioImpressao : undefined, usuarioEncerramento: undefined};

    if(registro.categoria){
      registro.categoria = FrontBusiness.getLabel(registro.categoria, RegistroAuxiliarBusiness.getTipos()).toLowerCase();
    }

    if(registro.tipoCedula){
      registro.tipoCedula = FrontBusiness.getLabel(registro.tipoCedula, RegistroAuxiliarBusiness.getTiposCedulas()).toLowerCase();
    }

    if(registro.status == 'PENDENTE' || registro.prematricula){
      registro.codigo = '<span class="text-info-soft">{{MATRICULA}}</span>'
      registro.cnm = '<span class="text-info-soft">{{CNM}}</span>'
    }

    return registro;
  },

  async gerarDicionarioEnvolvido(envolvido, proprietarios, indices = {}){

    const dicionarioEnvolvido = async function(versao = {}, envolvido = {}, proprietarios = {}, indices = {}){
      envolvido.representantes = envolvido?.representantes || [];
      let docRepresentantes = envolvido?.representantes?.map(re => re.documento || ' Documento não informado');
      let procuradorPrincipal = envolvido?.representantes?.find(re => re.principal) ?? envolvido?.representantes?.[0];
      let dados = await indicadorPessoal.tratarDicionarioPessoa(versao);
      let dicionario = {
        ...dados,
        consultaCNIB : envolvido.consultaCnib,
        papel : envolvido.papel,
        fracao : envolvido.fracao,
        procurador: procuradorPrincipal?.documento,
        procuradorId: procuradorPrincipal?.indicadorPessoalVersaoId,
        inventariante: envolvido.inventariante,
        inventarianteId:envolvido.inventarianteId,
        areaInformada: envolvido.area,
        medidaAreaInformada: envolvido.medidaArea,
        representantes: docRepresentantes.length >0? docRepresentantes.join(',') : "",
        conjuge : null
      };

      let id = versao?.indicadorPessoalId || versao?.indicadorPessoalVersao?.indicadorPessoalId;

      dicionario.proprietario = !!proprietarios[id];

      // verificar se transmissao é total
      if(envolvido.papel == 'TRANSMITENTE' && envolvido.fracao > 0 && proprietarios[id]){
        dicionario.transmissao = envolvido.fracao < proprietarios[id] ? 'PARCIAL' : 'TOTAL';
      }

      if(envolvido.papel == 'DEVEDOR' && envolvido.fracao > 0 && proprietarios[id]){
        dicionario.garantia = envolvido.fracao < proprietarios[id] ? 'PARCIAL' : 'TOTAL';
      }

      // if (dicionario.tipoPessoa == 'JURIDICA') {
      //   dicionario.cnpj = dicionario.documento;
      // }
      //
      // if (dicionario.tipoPessoa == 'FISICA') {
      //   dicionario.cpf = dicionario.documento;
      //
      //   dicionario.estadoCivil = FrontBusiness.getLabel(dicionario.estadoCivil, IndicadorPessoalBusiness.getEstadosCivil()).toLowerCase()
      //     .replace('o(a)', dicionario.sexo == 'FEMININO' ? 'a' : 'o');
      //
      //   dicionario.regimeCasamento = FrontBusiness.getLabel(dicionario.regimeCasamento, IndicadorPessoalBusiness.getRegimes()).toLowerCase();
      //
      // }
      //
      // dicionario.sexo = FrontBusiness.getLabel(dicionario.sexo, IndicadorPessoalBusiness.getSexos()).toLowerCase();
      // dicionario.tipoPessoa = FrontBusiness.getLabel(dicionario.tipoPessoa, IndicadorPessoalBusiness.getTiposPessoa()).toLowerCase();
      // dicionario.nacionalidade = FrontBusiness.getLabel(dicionario.nacionalidade, IndicadorPessoalBusiness.getNacionalidades(), 'id', dicionario.sexo).toLowerCase();
      //
      // let camposEndereco = ['cep', 'estado', 'cidade', 'bairro', 'quadra', 'lote', 'logradouro', 'numero', 'complemento', 'pais'];
      // dicionario.endereco = {};
      // dicionario.enderecoComercial = {};
      // camposEndereco.forEach(c => {
      //   dicionario.endereco[c] = dicionario?.[c];
      //   dicionario.enderecoComercial[c] = dicionario?.[`${c}Comercial`];
      // });
      //
      // dicionario.endereco.pais = FrontBusiness.getLabel(dicionario.endereco.pais, IndicadorPessoalBusiness.getNacionalidades(), 'id', 'pais');
      // dicionario.enderecoComercial.pais = FrontBusiness.getLabel(dicionario.enderecoComercial.pais, IndicadorPessoalBusiness.getNacionalidades(), 'id', 'pais');

      if(indices[id]){
        dicionario.checklist = {...indices[id]};
      }

      return dicionario;

    };

    let dicionario = await dicionarioEnvolvido(envolvido.indicadorPessoalVersao, envolvido, proprietarios, indices);

    const versao = envolvido.indicadorPessoalVersao || envolvido;

    if (versao.tipoPessoa == 'FISICA') {
      dicionario.conjuge = null;
      dicionario.convivente = null;

      if (versao.conjuge) {
        let conjuge = versao.conjuge;
        dicionario.conjuge = await dicionarioEnvolvido(conjuge, envolvido.conjuge, proprietarios, indices);
        if(versao.possuiUniaoEstavel && dicionario.estadoCivil.toLowerCase() !== 'casado' && dicionario.estadoCivil.toLowerCase() !== 'casada'){
          dicionario.convivente = dicionario.conjuge;
          dicionario.conjuge = null;
        }
      }

    }

    return dicionario;

  },

  async criarSubgrupo(lista, node, dicionario, callback, debug){

    let output = [], novaLista = [...lista];
    const bkp = {...dicionario.checklist };
    const grupoBkp = {...dicionario.grupo };

    if(node.params.agrupar !== undefined){
      let indices = node.params.agrupar.split(/\s*\|\s*/);
      novaLista = FrontBusiness.agruparArray(novaLista, indices, proxy);
    }

    if(debug){
      debug.agrupamento = [...novaLista];
    }

    let listar = node.params.lista;

    if(listar != null){
      dicionario[listar] = novaLista;
      return await callback(node.children, dicionario, debug);
    }

    for(let i in novaLista){
      dicionario.grupo = {...novaLista[i]};
      dicionario.checklist = {...bkp, ...dicionario.grupo, valores : undefined};
      output.push(await callback(node.children, dicionario, debug));

      dicionario.grupo = {...grupoBkp};
      dicionario.checklist = {...bkp};
    }

    return '  ' + FrontBusiness.formatArray(output, node.params.separador, node.params.ultimo)  + ' ';
  },

  async repetidorChecklist(node, dicionario, callback, debug){
    const val = dicionario.checklist[node.params.indice];
    let lista = (Array.isArray(val) ? val : [val]).filter(e => e);

    if(node.params.documento !== undefined){
      lista = lista.map(i => dicionario.documentos[i]).filter(e => e);
    }

    console.error("Método ultrapassado. Favor usar o repetidor.");

    return this.criarSubgrupo(lista, node, dicionario, callback, debug);
  },

  async agruparChecklist(dicionario, node, callback, debug){
    const lista = (Utils.clone(dicionario.grupo) || {}).valores || [];

    console.error("Método ultrapassado. Favor usar o repetidor.");

    return this.criarSubgrupo(lista, node, dicionario, callback, debug);
  },

  plural(node, dicionario, envolvidos, ato, debug){

    let genero = 'masculino', quantidade = 0, saidaPlural = '';

    if(node.params.grupo != undefined){

      let group = dicionario.checklist[node.params.grupo] || [];
      if(debug) {
        debug.group = group;
      }
      quantidade = group.length;

    }else{

      let filtrados = envolvidos.filter(e => !node.params.papel || e.papel.toLowerCase() == node.params.papel.toLowerCase());
      quantidade = filtrados.length;

      if(quantidade == filtrados.filter(e => (e.indicadorPessoalVersao.sexo || '').toUpperCase() == 'FEMININO').length){
        genero = 'feminino';
      }

    }

    if(debug) {
      debug.plural = {quantidade, genero};
    }

    if (quantidade == 1) {
      saidaPlural += genero != 'masculino' && node.params.singularFeminino ? node.params.singularFeminino : node.params.singular;
    } else {
      saidaPlural += genero != 'masculino' && node.params.pluralFeminino ? node.params.pluralFeminino : node.params.plural;
    }

    return getVar(saidaPlural, dicionario, false, true);

  },

  fracao(node, dicionario, envolvidos ){

    let valor = 0;

    let key = node.params.manual !== undefined ? 'area' : 'fracao';

    if(dicionario.envolvido){

      valor = dicionario.envolvido[key] || 0;
      let consorte = dicionario.envolvido.conjuge || dicionario.envolvido.convivente;

      if(consorte?.id && consorte?.papel == dicionario.envolvido.papel){

        if(node.params.casal !== undefined){
          valor = valor + consorte[key];
        }

        if(node.params.conjuge !== undefined || node.params.consorte !== undefined){
          valor = consorte[key];
        }

      }

    }

    if(node.params.transmitentes !== undefined){
      valor = envolvidos.reduce((total, e) => total + (e.papel == 'TRANSMITENTE' && e[key]? e[key] : 0), 0);
    }

    if(node.params.adquirentes !== undefined){
      valor = envolvidos.reduce((total, e) => total + (e.papel == 'ADQUIRENTE' && e[key]? e[key] : 0), 0);
    }

    if(node.params.devedores !== undefined){
      valor = envolvidos.reduce((total, e) => total + (e.papel == 'DEVEDOR' && e[key]? e[key] : 0), 0);
    }

    if(node.params.credores !== undefined){
      valor = envolvidos.reduce((total, e) => total + (e.papel == 'CREDOR' && e[key]? e[key] : 0), 0);
    }

    if(node.params.area !== undefined && key == 'fracao'){
      valor = valor / 100 * (dicionario.imovel.area || 1);
      valor = Utils.truncateNumber(valor, 2);
    }

    if(node.params.texto !== undefined){
      return FrontBusiness.toFraction(valor);
    }

    let maxDecimal = node.params.maximo !== undefined ? node.params.maximo : node.params.decimal;

    valor = node.params.arredondar !== undefined ? valor : Utils.truncateNumber(valor, maxDecimal || 20);

    if(node.params.extenso !== undefined){
      let valorStr = `${valor}`;
      if(valorStr.includes('.') && valorStr.split('.')[1].length == 1){
        valorStr = `${valor}0`;
      }

      if(node.params.area !== undefined){

        let metragem = IndicadorRealBusiness.getMedidas().find(e => e.id == dicionario.imovel.medidaArea);

        return extenso(valorStr.replace('.', ','), { mode: 'currency' })
          .replace('real', metragem?.singular || "metros")
                      .replace('reais', metragem?.plural || "metros")
                      .replace('centavos', metragem?.decimal || "decímetros") + (dicionario.imovel.medidaArea == 'M2' ? " quadrados" : "");
      }

      return extenso(valorStr.replace('.', ',')) + " por cento"; //, { mode: 'currency' }//

      // let inteiroStr = texto.includes('centavos') ? 'inteiro' : '';
      // let inteirosStr = texto.includes('centavos') ? 'inteiros' : '';
      //
      // const len = valorStr.split('.')?.[1]?.length;
      // let centavos = stringPercentual?.[len];
      //
      // return texto.replace('real', inteiroStr)
      //               .replace('reais', inteirosStr)
      //               .replace('centavos', centavos) + " por cento";
    }

    return parseFloat(valor).toLocaleString("pt-BR", {
      minimumFractionDigits: parseInt(node.params.decimal !== undefined ? node.params.decimal : 0, 10),
      maximumFractionDigits: parseInt(maxDecimal !== undefined ? maxDecimal : 2, 10),
      style: 'currency',
      currency: 'BRL'
    }).replace("R$ ", "") +
      (node.params.area !== undefined ? (dicionario.imovel.medidaArea || '').toLowerCase().replaceAll('2', '²') : '%');

  },

}
