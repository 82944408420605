<template>

  <modal :titulo="titulo" class="modal-detalhes-ato" id="detalhes-ato-registrado" :loading="loading" :fechar="true" @close="close">

    <template #actions>
      <li class="list-inline-item" v-if="!usuario.auditor && !ato.validacao">
        <a @click.prevent.stop="formulario(ato)" title="Editar"  href>
          <i class="fal fa-edit"></i>
        </a>
      </li>
    </template>

    <template v-if="!loading">

      <item-protocolo-legado tipo="ato" :id="ato.id" :dto="ato" @reload="load()" :dominio="dominio" />

      <card>
        <div class="row">
          <div class="col-3">
            <span class="mr-2 ws-title">{{descricaoProtocolo}} nº</span>{{protocoloCodigo}}
          </div>
          <div class="col-3">
            <span class="mr-2 ws-title">Registro:</span>
            <template v-if="ato.registro">{{$formatters.date(ato.registro)}}</template>
            <template v-else>-</template>
          </div>
          <div class="col">
            <span class="mr-2 ws-title">Selagem:</span>
            <template v-if="ato.selado">{{$formatters.date(ato.selado)}}</template>
            <template v-else>-</template>
          </div>

        </div>
      </card>

      <custas-container titulo="Detalhamento das Custas" tipo="ato" :item="ato" :protocolo="protocolo"/>
      <div class="w-100"></div>

      <auditoria-details-content :auditoria="auditoria" :formatters="formatters" :show-after="false" :title="`Detalhes do Ato`" v-if="!loading" />

    </template>

  </modal>

</template>

<script>

import AuditoriaBusiness from "@/business/AuditoriaBusiness.js";
import FrontBusiness from "@/business/FrontBusiness.js";
import IndicadorBusiness from "@/business/indicadores/IndicadorBusiness.js";
import AtoBusiness from "@/business/protocolo/AtoBusiness.js"
import ProtocoloBusiness from "@/business/protocolo/ProtocoloBusiness.js";
import CustasContainer from "@/components/Custas/CustasContainer.vue";
import DetalhesAto from "@/components/IndicadorReal/Detalhes/DetalhesAto.vue";
import AuditoriaDetailsContent from "@/v2/components/Auditoria/AuditoriaDetailsContent.vue";
import businessTipoServico from "@/views/Administracao/modulos/tipo-servico/business.js";
import Modal from "@/components/Modal.vue";
import Card from "@/components/Layout/components/Card.vue";

import doi from '@/views/Protocolo/Atos/Ato/DOI.vue'
import ItemProtocoloLegado from "@/views/Protocolo/Atos/Legado/ItemProtocoloLegado.vue";
import AnexosModal from "@/components/Anexos/AnexosModal.vue";

export default {

  name: "DetalhesAtoRegistrado",
  components: {
    AuditoriaDetailsContent,
    ItemProtocoloLegado,
    CustasContainer,
    Modal,
    Card
  },

  modal: {
    width: 900,
    escapable: false
  },

  props: {
    item: Object,
    protocoloId: String,
    ficha: Object
  },

  async mounted() {
    await this.load();
  },

  computed: {
    chave() {
      return `detalhes-ato-registrado-card`
    },
    titulo() {
      return this.ato.id ? this.getCodigo(this.ato, this.ficha) + ' - ' + (this.ato?.tipoServico?.opcoes?.titulo || this.ato?.titulo || '') : ''
    },
    protocoloCodigo() {
      return this.ato.protocoloId || this.ato.protocolo ? this.ato.protocoloCodigo || this.ato.protocolo?.codigo : '-'
    },
    descricaoProtocolo(){
      return this.protocolo?.tipoProtocolo == "PROCESSO_INTERNO" || this.ato?.protocoloTipo == "PROCESSO_INTERNO" ? 'Processo Interno' : 'Protocolo'
    },
    possuiAreaDoi(){
      return this.areas.filter(a => a.id === 'doi').length > 0 && !this.ato.tipoServico?.opcoes?.doiOpcional;
    },
    usuario() {
      return this.$root.usuarioLogado;
    },
    auditoria(){
      return {antes: this.ato, depois : {}, entidade: 'ATO', operacao : 'UPDATE'};
    },
    dominio(){
      return this.protocolo?.dominio || IndicadorBusiness.getDominioByLivro(this.ato.livro);
    }

  },

  data() {
    return {
      loading: false,
      ato: {},
      protocolo:{},
      formatters: []
    }
  },

  methods: {

    async formulario(ato = {}) {
      let response = await FrontBusiness.openModal(DetalhesAto, {id: ato.id, protocoloId: ato.protocoloId, ficha: this.ficha});
      this.alterouFicha = response;
      await FrontBusiness.sleep(500);
      await this.load();
    },

    async load() {
      this.loading = true;

      if (this.item?.protocoloId) {
        ProtocoloBusiness.getById(this.item.protocoloId).then(r => this.protocolo = r);
      }

      this.ato = await AtoBusiness.getById(this.item.protocoloId, this.item.id || this.item.atoId).catch(e => {
        FrontBusiness.showError('', e.message)
        this.close();
      });

      console.debug('ID do Ato: ', this.ato.id);

      this.formatters = (await AuditoriaBusiness.formattersAtos(this.auditoria))
        ?.filter(f => !['Exigências', 'Protocolo', 'Etapa', 'Tipo do Ato', 'Registro', 'Selado', 'Código', 'Pedido', 'Ordem', 'Envolvidos'].includes(f.label));

      this.formatters.push({
        label: 'Envolvidos',
        key: 'envolvidos',
        type: 'string',
        formatter: (value) => AuditoriaBusiness.envolvidos({envolvidos: value, doi: this.ato.tipoServico?.opcoes?.tipoTransacaoDoi !== 'NENHUM' && this.ato?.doi?.gerarDoi !== false })
      });


      this.loading = false;

      // if(this.ato?.id){
      //   this.ato.dto = this.ato.dto || {};
      //   this.ato.envolvidos = this.ato.envolvidos || [];
      //   this.ato.indices = this.ato.indices || {};
      //   this.ato.dto.doi = this.ato.dto.doi || {};
      //   this.ato.dto.doi = this.getDoiDTO(this.ato);
      //   this.ato.dto.indicador = this.ato.dto.indicador || {};
      //   this.ato.dto.camposAdicionais = this.ato.dto.camposAdicionais || {};
      //   this.ato.dto.valoresBaseCalculo = this.ato.dto.valoresBaseCalculo || {};
      //   this.ato.dto.isIsento = !!this.ato.dto.isIsento;
      //   this.ato.dto.multiplasCobrancas = !!this.ato.dto.multiplasCobrancas;
      //
      //   if(this.ato.tipoServico?.regraCustas){
      //     this.ato.camposAdicionais = await RegraCustasBusiness.getRegra(this.ato.tipoServico?.regraCustas).catch(e => null);
      //   }
      //
      //   this.dto = this.ato;
      //   await this.areaRestricao(this.ato);
      //
      //   await this.tratarChecklist();
      //   await this.$forceUpdate();
      // }
      //
      // await this.getProcurador();

      setTimeout(() => {
        const elements = document.querySelectorAll('.anexos-documentos');

        elements.forEach(element => {
          element.addEventListener('click', function() {
            let id = element.getAttribute('data-documento-id');
            FrontBusiness.openModal(AnexosModal, {id, entidade: "INDICADOR_DOCUMENTO"});
          });
        });
      }, 1000);
    },

    getCodigo(ato, ficha) {
      return AtoBusiness.getCodigo(ato, ficha, ato?.tipoServico?.opcoes?.tipoAto);
    },

    fundos(linha) {
      this.$set(linha, 'detalhar', !linha.detalhar);
    },

    close() {
      FrontBusiness.closeModal(this.alterouFicha)
    },

    nomePapel(papel){ return businessTipoServico.nomePapel(papel) },

  }
}
</script>

<style lang=scss>
#detalhes-ato-registrado {
  .modal-body {
    height: calc(100vh - 150px);
  }
}
</style>
