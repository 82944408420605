import IndicadorBusiness from "@/business/indicadores/IndicadorBusiness.js";
import AtoBusiness from "@/business/protocolo/AtoBusiness";

export default {

  gerarFichas(atos) {

    let fichas = {};

    atos.forEach(ato => {

      let ficha = ato.indicadorReal || ato.registroAuxiliar || ato.indicador;
      let key = `${ficha.id}-${ficha.livro}`;

      fichas[key] = fichas[key] || {
        ...ficha,
        key: key,
        atos: [],
        ordem: ato.ordem,
        opened: true,
      };

      let dto = {
        ...ato,
        indicadorReal: undefined,
        registroAuxiliar: undefined,
        indicador: undefined,
        selecionado: false
      };

      fichas[key].atos.push(dto);

    });

    return Object.values(fichas);

  },

  areasAto(ato) {

    let areas = [];

    areas.push({id: 'emolumentos', nome: 'Emolumentos'});

    // console.debug(ato);
    const opcoes = ato.tipoServico?.opcoes || {exibirBaseCalculo : true};

    if(!ato.tipoServico?.id){
      // opcoes.tipoTransacaoDoi
    }

    // GERENCIAR TRIBUTOS E RECOLHIMENTOS
    if (opcoes?.exibirBaseCalculo && IndicadorBusiness.getDominioByLivro(ato.livro) == 'PROTOCOLO_RI') {
      areas.push({id: 'recolhimentos', nome: 'Tributos e Recolhimentos'});
    }

    // GERENCIAR A DOI
    if (ato.livro != 'REGISTRO_AUXILIAR' && opcoes?.tipoTransacaoDoi && opcoes?.tipoTransacaoDoi !== 'NENHUM') {
      areas.push({id: 'doi', nome: 'DOI'});
    }

    // MÚLTIPLAS COBRANÇAS
    let multiplasCobrancas = (!ato.legado) && opcoes?.exibirMultiplasCobrancas;

    if (multiplasCobrancas) {
      areas.push({id: 'multiplas-cobrancas', nome: 'Multiplas Cobranças'});
    }

    // GERENCIAR DOCUMENTOS
    if (["REGISTRO_PJ", "REGISTRO_TD"].includes(ato?.livro)) {
      areas.push({id: 'documentos', nome: 'Documentos'});
    }

    // GERENCIAR ENVOLVIDOS
    if (opcoes?.gerenciarEnvolvidos !== false) {
      areas.push({id: 'envolvidos', nome: 'Partes Envolvidas'});
    }

    // GERENCIAR BENS E GARANTIAS
    if (opcoes?.gerenciarBensGarantias && ["REGISTRO_TD", "REGISTRO_AUXILIAR"].includes(ato?.livro)) {
      areas.push({id: 'bens-garantias', nome: 'Bens e Garantias'});
    }

    // LIVRO DO ESTRANGEIRO
    if (AtoBusiness.mostrarCampoLivroEstrangeiro(ato)) {
      areas.push({id: 'livro-estrangeiro', nome: 'Livro do Estrangeiro'});
    }

    // EDITAR INDICADOR REAL
    if (opcoes?.editarIndicadorReal && ['MATRICULA', 'TRANSCRICAO'].includes(ato.livro)) {
      areas.push({id: 'editar-indicador-real', nome: 'Indicador Real'});
    }

    // EDITAR REGISTRO AUXILIAR
    if (opcoes?.editarRegistroAuxiliar && ato.livro == 'REGISTRO_AUXILIAR') {
      areas.push({id: 'editar-registro-auxiliar', nome: 'Registro Auxiliar'});
    }

    // CRIAR RESTRIÇÕES INDICADOR
    if (opcoes?.criarRestricao?.length > 0) {
      areas.push({id: 'restricoes-card', nome: 'Restrições'});
    }

    // CANCELAR RESTRIÇÕES
    if (opcoes?.cancelarRestricao && opcoes?.cancelarRestricao?.length > 0) {
      areas.push({id: 'cancelar-restricoes', nome: 'Cancelar Restrições'});
    }

    // CANCELAR RESTRIÇÕES SEM REGISTRAR
    if (!ato.registro && opcoes?.cancelarRestricao && opcoes?.cancelarRestricao?.length > 0) {
      areas.push({id: 'cancelar-restricoes-nao-registradas', nome: 'Cancelar Restrições Não Registradas'});
    }

    // ATO RETIFICADOR
    if (opcoes?.atoRetificador) {
      areas.push({id: 'card-atos-indicador', nome: 'Retificar atos registrados e selados'});
    }

    return areas;

  },

}
