<template>

  <modal titulo="Capturar imagem do indicador pessoal">
    <v-input v-if="cameras.length > 1" v-model="selectedCameraId" @change="startCamera"
             :options="cameras" type="select" />

    <video v-if="!photoCaptured" ref="video" autoplay playsinline></video>
    <img v-else :src="photo" alt="Foto capturada" />

    <template #footer>
      <button type="button" @click.prevent="close()" class="btn btn-outline-secondary mr-auto">Cancelar</button>
      <v-button v-if="!photoCaptured" :run="capturePhoto" class="btn-outline-primary">Capturar Foto</v-button>
      <template v-else>
        <v-button :run="resetCamera" :popover="true" class="btn-outline-secondary mr-2">Tirar Outra Foto</v-button>
        <v-button :run="savePhoto" :popover="true" class="btn-outline-primary">Salvar</v-button>
      </template>
    </template>

  </modal>

</template>

<script>
import FrontBusiness from "@/business/FrontBusiness";
import Card from "@/components/Layout/components/Card.vue";
import Modal from "@/components/Modal";
import AnexoBusiness from "@/business/AnexoBusiness";

export default {
  name: "Imagem",
  components: {Card, Modal},
  props: {
    id: String,
  },

  modal:{
    width: 450
  },

  data() {
    return {
      form: {},
      validation: {},
      videoStream: null,
      photo: null,
      photoCaptured: false,
      cameras: [],
      selectedCameraId: null,
    }
  },
  methods: {

    async getCameras() {
      try {
        const devices = await navigator.mediaDevices.enumerateDevices();
        this.cameras = devices.filter((device) => device.kind === "videoinput").map((camera, i) => ({
          id: camera.deviceId,
          nome: camera.label || `Câmera ${i + 1}`
        }));
        if (this.cameras.length > 0) {
          this.selectedCameraId = this.cameras[0].id;
          await this.startCamera();
        }
      } catch (error) {
        console.error("Erro ao obter dispositivos de mídia:", error);
      }
    },

    async startCamera() {
      if (this.videoStream) {
        this.stopCamera();
      }
      try {
        this.videoStream = await navigator.mediaDevices.getUserMedia({
          video: { deviceId: this.selectedCameraId ? { exact: this.selectedCameraId } : undefined },
        });
        this.$refs.video.srcObject = this.videoStream;
      } catch (error) {
        console.error("Erro ao acessar a câmera:", error);
      }
    },

    capturePhoto() {
      const video = this.$refs.video;
      const canvas = document.createElement("canvas");
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      const context = canvas.getContext("2d");
      context.drawImage(video, 0, 0, canvas.width, canvas.height);
      this.photo = canvas.toDataURL("image/png");
      this.photoCaptured = true;
      this.stopCamera();
    },

    async savePhoto() {
      if (!this.photo) {
        console.error("Nenhuma foto capturada para salvar.");
        return;
      }

      const base64Data = this.photo.split(",")[1];
      const byteCharacters = atob(base64Data);
      const byteNumbers = new Array(byteCharacters.length).fill(null).map((_, i) => byteCharacters.charCodeAt(i));
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: "image/png" });

      const file = new File([blob], "photo.png", { type: "image/png" });

     await AnexoBusiness.inserir(file, 'INDICADOR_PESSOAL_CAPTURA', this.id, null, false, null, null)
        .then((response) => {
          FrontBusiness.showSuccess('', 'Captura salvo com sucesso')
          this.close();
        })
        .catch((error) => {
          FrontBusiness.showError('', "Erro ao salvar a captura", 'app', 5000);
          console.error("Erro ao salvar a foto:", error);
        });
    },

    stopCamera() {
      if (this.videoStream) {
        this.videoStream.getTracks().forEach((track) => track.stop());
      }
    },

    resetCamera() {
      this.photo = null;
      this.photoCaptured = false;
      this.startCamera();
    },

    close() {
      FrontBusiness.closeModal();
    },
  },
  mounted() {
    this.getCameras();
  },
  beforeDestroy() {
    this.stopCamera();
  },
};
</script>

<style scoped>
video {
  width: 100%;
}
img {
  width: 100%;
}
</style>
