<template>
  <div>
    <anexos-wrapper ref="anexos" nome="Anexo de imagens" entidade="INDICADOR_PESSOAL_CAPTURA"
                    :permiteExcluirAnexo="permitirExcluir"
                    :id="pessoa.indicadorPessoalId" :exibirBotoes="false" >
      <template #menu>
          <button v-if="permitirCapturar" style="margin-top: -12px;" class="btn btn-sm btn-outline-info pointer float-right" @click="capturar">Capturar Imagem</button>
      </template>
    </anexos-wrapper>
  </div>
</template>

<script>

import Card from "@/components/Layout/components/Card.vue";
import CardTable from "@/components/Layout/components/CardTable.vue";
import SmallLoading from "@/components/SmallLoading";
import FrontBusiness from "@/business/FrontBusiness";
import Imagem from "@/components/IndicadorPessoal/Detalhes/BiometriaImagem/Imagem.vue";
import AnexosWrapper from "@/components/Anexos/AnexosWrapper.vue";

export default {

  name: "BiometriaImagem",
  components: {AnexosWrapper, SmallLoading, CardTable, Card},
  props: {
    pessoa: Object,
  },

  data() {
    return {
      loading: false
    }
  }, //

  computed: {
    permitirCapturar(){
      return this.$root.getPermission({id: 'PERMITIR_CAPTURAR_IMAGEM_INDICADOR_PESSOAL'});
    },
    permitirExcluir(){
      return this.$root.getPermission({id: 'PERMITIR_EXCLUIR_IMAGEM_INDICADOR_PESSOAL'});
    },
  },

  methods: {
    async capturar() {
      await FrontBusiness.openModal(Imagem, {id: this.pessoa.indicadorPessoalId})
      this.$refs.anexos.load();
    }
  }
}
</script>

<style lang=scss>

</style>
