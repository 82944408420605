import VueBusiness from "@/business/VueBusiness";

export default {

  exibirGuia() {
    if(['PE', 'BA', 'SE'].includes(VueBusiness.getVueRoot().estado)){
      return true;
    }
    return false;
  },

  getFormasPagamento() {
    if(VueBusiness.getVueRoot().isEstadoPE){
      return [{id: 'SICASE', nome: 'SICASE'}, {id: 'SICASE_COMPLEMENTAR', nome: 'SICASE Complementar'}];
    }
    if(VueBusiness.getVueRoot().isEstadoBA){
      return [{id: 'DAJE', nome: 'DAJE'}, {id: 'DAJE_COMPLEMENTAR', nome: 'DAJE Complementar'}];
    }
    if(VueBusiness.getVueRoot().isEstadoSE){
      return [{id: 'DAJE', nome: 'DAJE'}, {id: 'DAJE_COMPLEMENTAR', nome: 'DAJE Complementar'}];
    }
    return [];
  },

  getMascarasGuiaTribunal() {
    if(VueBusiness.getVueRoot().isEstadoPE){
      return ['########'];
    }
    if(VueBusiness.getVueRoot().isEstadoBA){
      return ['####.###.######'];
    }
    if(VueBusiness.getVueRoot().isEstadoSE){
      return ['############'];
    }
    return null;
  },

  getTamanhoGuiaTribunal() {
    if(VueBusiness.getVueRoot().isEstadoPE){
      return 8;
    }

    if(VueBusiness.getVueRoot().isEstadoBA){
      return 15;
    }

    if(VueBusiness.getVueRoot().isEstadoSE){
      return 12;
    }
    return null;
  },

  getNotasAcrescimo() {
    return [];
  },

  getNotasDesconto() {
    return [];
  }
}
