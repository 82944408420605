<template>
  <modal titulo="Detalhes DAJE">
    <small-loading :loading="true" v-if="loading" class="py-4"></small-loading>
    <card-content v-if="!loading">
      <table class="table table-striped card-table table-vcenter">
        <thead>
          <tr>
            <th class="pl-2">Código</th>
            <th class="pl-2">Tipo</th>
            <th class="pl-2">Emissor</th>
            <th class="pl-2">Valor</th>
            <th class="pl-2">Status</th>
            <th class="pl-2">Geração</th>
            <th class="pl-2">Vencimento</th>
            <th class="pl-2">Pagamento</th>
            <th class="pl-2"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in items" :key="'ficha_indisponibilidade'+index">
            <td class="pr-2">{{`${item.codigoEmissor}.${item.serie}.${item.numSequencial}`}}</td>
            <td class="pr-2">{{item.tipoDaj === 'DAJ_PADRAO' ? 'Normal' : 'Complementar'}}</td>
            <td class="pr-2">{{item.contribuinte}}
              <div class="w-100 mt-1 small text-muted">
                Documento: {{ item.cpfcnpj }}
              </div>
            </td>
            <td class="pr-2">{{$formatters.currency(item.valorDaj, 2, '-')}}</td>
            <td class="pr-2">{{item.status}}</td>
            <td class="pr-2">{{item.dataEmissao | data}}</td>
            <td class="pr-2">{{item.dataVencimento | data}}</td>
            <td class="pr-2">{{item.dataPagamento | data}}</td>
            <td class="pr-2" style="min-width: 60px;">
              <v-button class="icon mr-2" v-if="$root.isVhlUser && item.status === 'Não Pago'" position="dropleft" :run="pagar" :params="[item]" :button="false" :popover="true" title="Pagar Daje">
                <i class="fa fa-money-bill"></i>
              </v-button>
              <v-button class="icon mr-2" :run="justificar" :params="[item]" title="Justificar Daje"
                        v-if="PERMITIR_JUSTIFICAR_DAJE" :button="false">
                <i class="fa fa-comment-dots"></i>
              </v-button>
              <v-button class="icon mr-2" :run="cancelarDaje" :params="[item]" title="Cancelar Daje"
                        v-if="PERMITIR_CANCELAR_DAJE" :button="false">
                <i class="fa fa-times"></i>
              </v-button>
            </td>
          </tr>
        </tbody>
      </table>
    </card-content>

  </modal>
</template>

<script>
  import darfBusiness from "@/business/DarfBusiness.js";
  import FrontBusiness from "@/business/FrontBusiness";
  import CardContent from "@/components/Layout/components/CardContent.vue";
  import Modal from "@/components/Modal";
  import SmallLoading from "@/components/SmallLoading.vue";
  import ModalJustificarDaje from "@/components/Guia/ModalJustificarDaje.vue";

  export default {
    name: "ModalDetalhesDaje",
    components: {SmallLoading, Modal, CardContent},
    props: {
      item : Object,
    },
    data() {
      return {
        items : [],
        loading : true
      }
    },

    modal: {
      width: 1100
    },

    async mounted() {
      this.items = await this.$api.get(`/api/v2/regras-estaduais/BA/daje/${this.item.guia}`)
        .catch(e => {
          FrontBusiness.showError('Erro ao tentar consultar a Número da Guia', e?.message || e);
          this.close();
        });
      this.loading = false;
    },

    computed:{
      PERMITIR_JUSTIFICAR_DAJE() {
        return this.$root.getPermission({id: 'PERMITIR_JUSTIFICAR_DAJE', dominio: this.item?.protocolo?.dominio || 'PROTOCOLO_RI'});
      },

      PERMITIR_CANCELAR_DAJE() {
        return this.$root.getPermission({id: 'PERMITIR_CANCELAR_DAJE', dominio: this.item?.protocolo?.dominio || 'PROTOCOLO_RI'});
      },
    },

    methods:{

      close(resultado) {
        FrontBusiness.closeModal(resultado);
      },

      async pagar(item) {
        let guia = `${item.codigoEmissor}.${item.serie}.${item.numSequencial}`;
        let response = await this.$api.get(`/api/v2/regras-estaduais/BA/${this.item?.protocolo?.id}/pagar-daje/${guia}`)
          .catch(e => {
            FrontBusiness.showError('Erro ao tentar pagar DAJE', e?.message || e);
          });
        if(response){
          FrontBusiness.showSuccess('', "DAJE pago com sucesso.")
          this.close()
        }
      },

      async cancelarDaje(item) {
        let guia = `${item.codigoEmissor}.${item.serie}.${item.numSequencial}`;
        let response = await this.$api.get(`/api/v2/regras-estaduais/BA/${this.item?.protocolo?.id}/cancelar-daje/${guia}`)
          .catch(e => {
            FrontBusiness.showError('Erro ao tentar cancelar DAJE', e?.message || e);
          });
        if(response){
          FrontBusiness.showSuccess('', "DAJE cancelado com sucesso.")
          this.close()
        }
      },

      justificar(item) {
        FrontBusiness.openModal(ModalJustificarDaje, {item, protocolo: this.item?.protocolo});
      }

    }
  }
</script>

<style>

</style>
