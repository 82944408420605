<template>
  <modal id="form-indicador-pessoal" :titulo="titulo" :loading="!form || loading">

    <template #actions>

      <li class="list-inline-item" v-if="form && form.indicadorPessoalId">
        <a @click.prevent="consultarVersoes()" title="Consultar Versões" href>
          CONSULTAR VERSÕES
        </a>
      </li>

    </template>

    <form @submit.prevent="" v-if="form" autocomplete="off">
      <card>

        <card-content class="row gutters-xs">

          <v-input v-model="form.versao" type="text" :disabled="true" label="Versão" class="col-1"/>

          <v-input v-model="form.nome" :error="validation.nome" @blur="validarNome()"
                   type="text" :required="true" label="Nome" class="col dv-focus-nome"/>

          <div class="w-100"></div>

          <v-input :default="!form.id ? 'FISICA' : null" v-model="form.tipoPessoa"
                   :options="options.tiposPessoa" type="select"
                   :required="true" label="Tipo de pessoa" class="col-4"/>

          <v-input v-model="form.documentoDesconhecido" :label="labelDocumentoDesconhecido" type="radio" class="col-3"/>

          <template v-if="!form.documentoDesconhecido">
            <template v-if="form.tipoPessoa == 'JURIDICA'">
              <v-input :id="'doc'+form.tipoPessoa" :error="validation.documento" :required="true" v-model="form.documento"
                       @blur="validarDocumento()" type="cnpj" label="CNPJ"  class="col-3" :disabled="disabledDocumento"/>
            </template>

            <template v-else>
              <v-input :id="'doc'+form.tipoPessoa" :error="validation.documento" :required="true" v-model="form.documento"
                       @blur="validarDocumento()" type="cpf" label="CPF" class="col-5" :disabled="disabledDocumento" />
            </template>
          </template>
          <template v-else>
            <v-input v-model="form.motivoDocumentoDesconhecido"
                     :options="options.motivoDocumentoDesconhecido"
                     :error="validation.motivoDocumentoDesconhecido"
                     type="v-select" :label="labelMotivoDocumentoDesconhecido" class="col"/>
          </template>

          <template v-if="form.tipoPessoa == 'FISICA'">

            <div class="w-100"></div>
            <v-input class="col" v-model="form.rg" type="text" label="RG"/>
            <v-input class="col" v-model="form.cnh" type="text" label="CNH"/>
            <v-input class="col" v-model="form.cip" type="text" label="Identidade profissional"/>
            <v-input class="col" v-model="form.passaporte" type="text" label="Passaporte"/>
            <div class="w-100"></div>

            <v-input class="col" v-model="form.outrosDocumentos" type="text" label="Outros Documentos"/>
            <div class="w-100"></div>

            <v-input v-model="form.falecido" label="Pessoa falecida?" type="radio" class="col" />
            <v-input v-model="form.menor" label="Menor de idade?" type="radio" class="col" />
            <v-input v-model="form.incapaz" label="Incapaz?" type="radio" class="col" />
            <div class="w-100"></div>
            <v-input v-model="form.expostaPoliticamente" label="Pessoa exposta politicamente?" type="radio" class="col" />
            <v-input v-model="form.servidorPublico" label="Servidor Público?" type="radio" class="col" />

            <div class="w-100"></div>

          </template>

          <template  v-if="form.tipoPessoa == 'JURIDICA'">

            <div class="w-100"></div>

            <v-input v-model="form.denominacao" type="text" label="Nome Fantasia (Denominação)" class="col" />

            <v-input class="col-3" :error="validation.sexo" v-model="form.sexo" :options="options.sexos" type="select"
                     label="Gênero no texto"/>
          </template>

          <div class="w-100"></div>

          <template v-if="form.tipoPessoa == 'JURIDICA'">
            <v-input v-model="form.substituicaoTributaria" label="Optante de Substituição Tributária?" type="radio" class="col"/>
          </template>
          <v-input v-model="form.pessoaObrigada" label="Pessoa Obrigada?" type="radio" class="col" />
          <v-input v-model="form.fornecedor" label="Permitir uso como fornecedor?" type="radio" class="col" :disabled="fornecedor"/>
          <div class="w-100"></div>

          <v-input v-model="form.sequencial" label="Sequencial" type="text" class="col"/>

        </card-content>

      </card>

      <card titulo="Dados básicos" v-if="form.tipoPessoa == 'FISICA'">
        <card-content class="row gutters-xs">

          <v-input class="col" :error="validation.sexo" v-model="form.sexo" :options="options.sexos" type="select"
                   label="Sexo"/>

          <v-input class="col" v-model="form.nascimento" type="date" label="Data de nascimento"/>

          <v-input v-if="options.nacionalidades.length > 0" class="col-3" v-model="form.nacionalidade"
                   :options="options.nacionalidades" type="v-select" label="Nacionalidade"/>

          <v-input class="col-3" label="Profissão" v-model="form.profissao"
                   :error="validation.profissao" type="text" />

          <div class="w-100"></div>

          <v-input class="col" v-model="form.nomePai" type="text" label="Nome do pai"/>
          <v-input class="col" v-model="form.nomeMae" type="text" label="Nome da mãe"/>

          <v-input class="col-3" v-model="form.estadoCivil" :options="options.estadosCivil" type="select"
                   label="Estado civil"/>

          <v-input class="col-auto" v-model="form.possuiUniaoEstavel" v-if="form.estadoCivil != 'CASADO'" :options="options.uniaoEstavel"
                   type="select"  label="União estável?"/>

        </card-content>
      </card>

      <card :titulo="`${form.estadoCivil === 'CASADO' ? 'Dados do casamento' : 'Dados da união estável'}`"
           v-if="form.tipoPessoa === 'FISICA' && (form.estadoCivil === 'CASADO' || form.possuiUniaoEstavel === 'SIM')">

        <template #acoes v-if="form.conjuge && form.conjuge.id">
          <v-button :button="false" :popover="true" :run="importar" class="btn btn-sm btn-outline-primary mx-1 pointer">
            Importar dados cônjuge
          </v-button>
          <v-button v-if="pessoa.id && !$root.isVhlUser" :button="false" :popover="true" :run="exportar" class="btn btn-sm btn-outline-primary mx-1 pointer"
                    position="dropleft">
            Exportar dados cônjuge
          </v-button>
        </template>

        <card-content class="row gutters-xs">

          <!-- ENCONTRAR CONJUGE -->
          <template v-if="!form.conjuge || !form.conjuge.id">

            <v-input class="col" :label="consorte">
              <buscar-pessoa @add="selecionarConjuge($event)" :novo="true" />
            </v-input>

          </template>

          <template v-else>

            <v-input class="col form-group" :label="consorte">
              <input type="text" readonly v-model="consorteDescricao" class="form-control form-control-xs" />
            </v-input>

            <div class="col-auto">
              <button class="btn btn-outline-secondary btn-xs" style="margin-top: 22px" @click="selecionarConjuge(null)" type="button" title="Remover">
                <i class="fal fa-times"></i>
              </button>
            </div>

            <div class="col-auto">
              <button class="btn btn-outline-secondary btn-xs" style="margin-top: 22px" @click="editarConjuge(form.conjuge)" type="button">
                <i class="fal fa-edit"></i>
              </button>
            </div>

          </template>

          <div class="w-100"></div>

          <v-input v-model="form.casamentoAposLei6515" label="Após lei 6515?"
                   v-if="form.estadoCivil === 'CASADO'"
                   type="radio" class="col-auto"/>

          <v-input class="col-3" v-model="form.dataCasamento" type="date"
                   :label="form.estadoCivil === 'CASADO' ? 'Data do casamento' : 'Data da união'"/>

          <v-input class="col" v-model="form.regimeCasamento" :options="regimes" type="select" label="Regime"/>

          <div class="w-100"></div>

          <v-input class="col" v-model="form.detalhesRegime" type="editor" label="Detalhes do regime"/>

          <div class="w-100"></div>

          <template v-if="form.estadoCivil === 'CASADO'">

            <v-input v-model="form.adicionarRegistroPactoAntenupcial" label="Adicionar dados de registro do pacto antenupcial?"
                     type="radio" class="col-auto"/>

            <div class="w-100"></div>

            <v-input v-model="form.numeroRegistroPactoAntenupcial" label="Número do registro"
                     v-if="form.adicionarRegistroPactoAntenupcial"
                     type="text" class="col-6"/>

            <v-input v-model="form.registroImoveisPactoAntenupcial" label="Registro de Imóveis"
                     v-if="form.adicionarRegistroPactoAntenupcial"
                     type="text" class="col-6"/>

          </template>

        </card-content>

      </card>

      <card titulo="Dados Bancários" v-if="form.fornecedor">
        <card-content class="row gutters-xs">

          <v-input class="col" v-model="form.banco" type="text" label="Banco"/>
          <v-input class="col" v-model="form.agencia" type="text" label="Agência"/>
          <v-input class="col" v-model="form.conta" type="text" label="Conta"/>

        </card-content>

      </card>

      <card titulo="Dados básicos" v-if="form.tipoPessoa == 'JURIDICA'">
        <card-content class="row gutters-xs">

          <v-input class="col-auto" v-model="form.inscricaoEstadual" type="text" label="Inscrição estadual"/>
          <v-input class="col-auto" v-model="form.inscricaoMunicipal" type="text" label="Inscrição municipal"/>

          <v-input v-model="form.possuiParticipacaoEstrangeira" label="Participação estrangeira?"
                   type="radio" class="col-auto"/>
          <div class="w-100" />
          <v-input class="col-12" type="v-select" :options="options.naturezaJuridica" label="Natureza Jurídica"
                   v-model="form.naturezaJuridica" />

          <v-input v-if="form.naturezaJuridica === 'OUTRAS'" class="col-12" type="text" label="Descrição"
                   v-model="form.naturezaJuridicaOutras" />

        </card-content>

      </card>

      <card titulo="Contato">
        <card-content class="row gutters-xs">

          <v-input class="col" :error="validation.email" @blur="validarEmail()" v-model="form.email" type="email" label="E-mail"/>
          <v-input class="col-3" v-model="form.telefone" type="telefone" label="Telefone"/>
          <v-input class="col-3" v-model="form.celular" type="telefone" label="Celular"/>
          <v-input class="col-3" v-model="form.comercial" type="telefone" label="Comercial"/>

        </card-content>

      </card>

      <card titulo="Endereço">
        <card-content class="row gutters-xs">

          <v-input-cep :validation="validation.cep" v-model="form.cep" @endereco="atualizarEndereco" />

          <v-input class="col" v-model="form.logradouro" type="text" label="Logradouro (rua)"/>
          <v-input class="col-2" v-model="form.numero" type="text" label="Número"/>
          <div class="w-100"></div>
          <v-input class="col" v-model="form.quadra" type="text" label="Quadra"/>
          <v-input class="col" v-model="form.lote" type="text" label="Lote"/>

          <div class="w-100"></div>

          <v-input class="col" v-model="form.bairro" type="text" label="Bairro (setor)"/>
          <v-input class="col" v-model="form.cidade" type="text" label="Cidade"/>

          <v-input class="col-3" v-model="form.pais" :options="options.nacionalidades" default="BRASIL" type="select-name" nameOptionSelect="pais" label="País"/>
          <v-input class="col-auto" v-model="form.estado" :options="options.estados" type="select" label="Estado" v-if="form.pais == 'BRASIL'" />

          <div class="w-100"></div>

          <v-input class="col" v-model="form.complemento" type="text" label="Complemento"/>

        </card-content>
      </card>

      <card titulo="Endereço comercial" v-if="form.tipoPessoa == 'FISICA'">
        <card-content class="row gutters-xs">

          <v-input-cep :validation="validation.cepComercial" v-model="form.cepComercial" @endereco="atualizarEndereco($event, 'Comercial')" />

          <v-input class="col" v-model="form.logradouroComercial" type="text" label="Logradouro (rua)"/>
          <v-input class="col-2" v-model="form.numeroComercial" type="text" label="Número"/>
          <div class="w-100"></div>
          <v-input class="col" v-model="form.quadraComercial" type="text" label="Quadra"/>
          <v-input class="col" v-model="form.loteComercial" type="text" label="Lote"/>

          <div class="w-100"></div>

          <v-input class="col" v-model="form.bairroComercial" type="text" label="Bairro (setor)"/>
          <v-input class="col" v-model="form.cidadeComercial" type="text" label="Cidade"/>
          <v-input class="col-auto" v-model="form.estadoComercial" :options="options.estados" type="select" label="Estado"/>

          <v-input class="col-3" v-model="form.paisComercial" :options="options.nacionalidades" type="select-name" nameOptionSelect="pais" label="País"/>

          <div class="w-100"></div>

          <v-input class="col" v-model="form.complementoComercial" type="text" label="Complemento"/>

        </card-content>
      </card>

      <card>
        <card-content>
          <v-input type="editor" label="Observações" v-model="form.observacao" />
        </card-content>
      </card>

    </form>

    <template #footer>
      <button type="button" @click.prevent="close()" class="btn btn-outline-secondary">Cancelar</button>
      <template v-if="!desativarVersionamento">
        <v-button :run="salvar" :popover="true" :params="[true]" class="btn-outline-info ml-1" v-if="form && form.indicadorPessoalId">Editar Versão Atual</v-button>
        <v-button :run="salvar" class="btn-outline-primary ml-auto">Nova Versão</v-button>
      </template>
      <template v-else>
        <v-button :run="salvar" :popover="true" :params="[true]" class="btn-outline-primary ml-auto">Salvar</v-button>
      </template>
    </template>

  </modal>
</template>

<script>
import EnderecoBusiness from "@/business/EnderecoBusiness";
import FrontBusiness from "@/business/FrontBusiness";
import IndicadorPessoalBusiness from "@/business/indicadores/IndicadorPessoalBusiness";
import Utils from "@/commons/Utils";
import FormIndicadorPessoal from "@/components/IndicadorPessoal/Form/FormIndicadorPessoal";
import ModalVersoes from "@/components/IndicadorPessoal/Form/ModalVersoes";
import Card from "@/components/Layout/components/Card.vue";
import CardContent from "@/components/Layout/components/CardContent.vue";
import Modal from "@/components/Modal";

export default {
    name: "FormIndicadorPessoal",
    components: {CardContent, Card, Modal},
    props: {
      versaoId: String,
      configurandoConjuge: Boolean,
      fornecedor : {type : Boolean, default : false},
    },

    modal: {
      escapable: false
    },

    data() {
      return {
        form: null,
        validation: {},
        versoes : [],
        nome: null,
        documento: null,
        sequencial: null,
        tipoPessoa: null,
        versao : this.versaoId,
        loading : true,
        options: {
          tiposPessoa: IndicadorPessoalBusiness.getTiposPessoa(),
          estadosCivil: IndicadorPessoalBusiness.getEstadosCivil(),
          uniaoEstavel: IndicadorPessoalBusiness.getUniaoEstavel(),
          sexos: IndicadorPessoalBusiness.getSexos(),
          regimes: IndicadorPessoalBusiness.getRegimes(),
          nacionalidades: IndicadorPessoalBusiness.getNacionalidades(),
          estados: [{id : '', nome : ''}, ...EnderecoBusiness.getEstados()],
          naturezaJuridica: [],
          // TODO: Ajustar para $root.enums no futuro
          motivoDocumentoDesconhecido: [{id: 'DECISAO_JUDICIAL', nome: 'Sem CPF/CNPJ - Decisão Judicial'}, {id: 'NAO_CONSTA', nome: 'Não consta no documento'}]
        },
        pessoa: {},
        selecionado: this.versaoId,
        camposConjuge: ['estadoCivil', 'possuiUniaoEstavel', 'casamentoAposLei6515', 'dataCasamento', 'regimeCasamento'
          , 'detalhesRegime', 'cep', 'logradouro', 'numero', 'quadra', 'lote', 'bairro', 'cidade', 'estado', 'pais', 'complemento',
          'adicionarRegistroPactoAntenupcial', 'numeroRegistroPactoAntenupcial', 'registroImoveisPactoAntenupcial']
      }
    },

    async mounted() {
      this.options.naturezaJuridica = IndicadorPessoalBusiness.getNaturezaJuridica();
      await this.load();
      FrontBusiness.setFocus(this.$el, 'form-indicador-pessoal form .dv-focus-nome div input');
    },

    watch: {
        'form.documentoDesconhecido': function (n) {
            if(n == true || n == 'true') {
                this.$set(this.form, 'documento', null)
                this.$set(this.validation, 'documento', null);
            };
        }
    },

    computed: {

      ativarSubstituicaoTributaria(){
        return this.$root.config?.notaFiscal?.substituicaoTributaria;
      },

      desativarVersionamento(){
        return this.$root?.config?.geral?.desativarVersionamentoPessoas;
      },

      titulo() {
       return this.pessoa?.nome ? `${this.pessoa.nome}${this.pessoa.documento ? ' - ' + this.pessoa.documento : ''}` : 'Detalhes Indicador Pessoal';
      },

      regimes() {
        return this.options.regimes.filter(e => this.form.casamentoAposLei6515 || e.id != 'PARTICIPACAO_FINAL_AQUESTOS');
      },

      consorte(){
        return this.form.possuiUniaoEstavel === 'SIM' && this.form.estadoCivil !== 'CASADO' ? 'Convivente': 'Cônjuge';
      },

      consorteDescricao() {
        return `${this.form.conjuge.nome} (Versão: ${this.form.conjuge?.versao})`;
      },

      indicadorPessoalId(){
        return this.form?.indicadorPessoalId;
      },

      labelDocumentoDesconhecido(){
        return (this.form.tipoPessoa == 'JURIDICA' ? 'CNPJ' : 'CPF') + ' desconhecido?';
      },

      labelMotivoDocumentoDesconhecido() {
        return `Motivo do ${this.form.tipoPessoa == 'JURIDICA' ? 'CNPJ' : 'CPF'} desconhecido`;
      },

      disabledDocumento() {
         return this.form.documentoDesconhecido == true || this.form.documentoDesconhecido == 'true';
      }

    },

    methods: {

      async load(){
        this.$set(this, 'loading', true);

        let indicador = {
          nacionalidade : 'BRASIL', pais: 'BRASIL', paisComercial: 'BRASIL',
          falecido: false,
          menor: false,
          incapaz: false,
          expostaPoliticamente: false,
          pessoaObrigada: false,
          servidorPublico: false,
          fornecedor: this.fornecedor
        };

        if (this.selecionado) {
          indicador = await IndicadorPessoalBusiness.getVersaoById(this.selecionado);
        }

        this.documento = indicador.documento;
        this.sequencial = indicador.sequencial;
        this.tipoPessoa = indicador.tipoPessoa;
        this.pessoa = indicador;
        this.nome = indicador.nome;

        this.$set(this, 'form', {...indicador});
        this.$set(this.form, 'possuiUniaoEstavel', indicador?.possuiUniaoEstavel ? 'SIM' : (indicador.possuiUniaoEstavel === null ? 'DESCONHECIDO' : 'NAO'));
        this.$set(this.form, 'documentoDesconhecido', indicador?.indicadorPessoal?.documentoDesconhecido || false);

        this.$set(this, 'loading', false);
        this.$forceUpdate();

      },

      close() {
        FrontBusiness.closeModal();
      },

      showError(e) {
        FrontBusiness.fullErrorHandler(this, e, '#form-indicador-pessoal');
      },

      async salvar(editar = false) {

        let indicador = null;

        if (!(this.form.tipoPessoa == 'FISICA' && (this.form.estadoCivil == 'CASADO' || this.form.possuiUniaoEstavel === 'SIM'))) {
          this.$set(this.form, 'conjuge', null);
          this.$set(this.form, 'casamentoAposLei6515', null);
          this.$set(this.form, 'dataCasamento', null);
          this.$set(this.form, 'regimeCasamento', null);
          this.$set(this.form, 'detalhesRegime', null);
        }

        if(this.form.tipoPessoa == 'FISICA' && this.form.estadoCivil == 'CASADO'){
          this.$set(this.form, 'possuiUniaoEstavel', 'NAO');
        }

        if(this.form.pais != 'BRASIL'){
          this.$set(this.form, 'estado', null);
        }

        if(!this.form.adicionarRegistroPactoAntenupcial) {
          this.$set(this.form, 'numeroRegistroPactoAntenupcial', null);
          this.$set(this.form, 'registroImoveisPactoAntenupcial', null);
        }

        if (this.form.naturezaJuridica !== 'OUTRAS') {
          this.$set(this.form, 'naturezaJuridicaOutras', null);
        }

        let dto = Utils.clone(this.form);
        dto.indicadorPessoal = {
          id : this.form.indicadorPessoalId,
          nome : this.form.nome,
          documento : !this.disabledDocumento ? this.form.documento: null,
          tipoPessoa : this.form.tipoPessoa,
          telefone : this.form.telefone,
          celular : this.form.celular,
          comercial : this.form.comercial,
          email : this.form.email,
          documentoDesconhecido: this.disabledDocumento,
          fornecedor: this.form.fornecedor,
          banco : this.form.fornecedor? this.form.banco : null,
          agencia : this.form.fornecedor? this.form.agencia : null,
          conta : this.form.fornecedor? this.form.conta : null
        };
        dto.possuiUniaoEstavel = this.form.possuiUniaoEstavel === 'SIM' ? true : (this.form.possuiUniaoEstavel === 'NAO' ? false : null)

        await IndicadorPessoalBusiness.validarDadosCadastroIndicador(dto).then(async (e) => {
            let result = await this.confirmarSalvarAlteracoes(dto);
            if (!result) return;

            if(this.selecionado){
              indicador = await IndicadorPessoalBusiness.criarVersao(this.form.indicadorPessoalId, editar, dto).catch(this.showError);
            }else{
              indicador = await IndicadorPessoalBusiness.criarIndicador(dto).catch(this.showError);
            }

            if (indicador) {
              FrontBusiness.closeModal(indicador);
            }
        }).catch(e => {
          FrontBusiness.fullErrorHandler(this, e, '#form-indicador-pessoal');
        });


      },

      async validarDocumento(){
          if (this.form.documento){
            this.$set(this.validation, 'documento', await IndicadorPessoalBusiness.validarDocumentos(this.form.tipoPessoa, this.form.documento.replace(/[^\d]/g, ''), true));
          } else if (!this.form.documento){
              this.$set(this.validation, 'documento', `Necessário informar um ${this.form.tipoPessoa == 'JURIDICA' ? 'CNPJ' : 'CPF'}`);
          }
      },

      async validarNome(){
        if (this.form.nome){
          this.$set(this.validation, 'nome', await IndicadorPessoalBusiness.validarNome(this.form.nome));
        } else if (!this.form.nome){
          this.$set(this.validation, 'nome', `Necessário informar um nome`);
        }
      },

      async validarEmail(){
        if (this.form.email){
          this.$set(this.validation, 'email', await IndicadorPessoalBusiness.validarEmail(this.form.email));
        } else if (!this.form.email){
          this.$set(this.validation, 'email', `Necessário informar um e-mail`);
        }
      },

      selecionarConjuge(conjuge){
        this.$set(this.form, 'conjuge', conjuge);
      },

      async editarConjuge(envolvido) {
        let retorno = await FrontBusiness.openModal(FormIndicadorPessoal, {versaoId: envolvido?.id, configurandoConjuge: true});
        if (retorno) {
          this.selecionarConjuge(retorno);
        }
      },

      atualizarEndereco(endereco, comercial = ''){
        if (endereco) {
          this.$set(this.form, 'cep'+comercial, endereco.cep || this.form['cep'+comercial]);
          this.$set(this.form, 'logradouro'+comercial, endereco.logradouro || this.form['logradouro'+comercial]);
          this.$set(this.form, 'bairro'+comercial, endereco.bairro || this.form['bairro'+comercial]);
          this.$set(this.form, 'cidade'+comercial, endereco.cidade || this.form['cidade'+comercial]);
          this.$set(this.form, 'estado'+comercial, endereco.estado || this.form['estado'+comercial]);
          this.getFocusElements('Logradouro (rua)');
        }
      },

      getFocusElements(campo) {
        let editor_elements = (document.getElementById('form-indicador-pessoal'))?.querySelectorAll('input, input.form-check-input, select, textarea, a, .document-editor__editable, .add-editor')
        if (!editor_elements) return;
        if (campo == null) {
          editor_elements[0].focus();
        } else {
          for (let i = 0; i < editor_elements.length; i++) {
            let id_campo = (editor_elements[i].id).split('-')[1] == 'in' ? (editor_elements[i].id).split('-')[0] + '-' + editor_elements[i].value : (editor_elements[i].id).split('-')[0];
            if ((campo).indexOf('ENVOLVIDO') != -1) id_campo = (editor_elements[i].id).replace(/-/g, '');
            if (i + 1 < editor_elements.length && id_campo.indexOf(campo) != -1) {
              editor_elements[i].focus();
              break
            }
          }
        }
      },

      async consultarVersoes(){
        let retorno = await FrontBusiness.openModal(ModalVersoes, {pessoa: this.pessoa});
        if(retorno) {
          this.$set(this, 'selecionado', retorno);
          await this.load()
        }
      },

      async confirmarSalvarAlteracoes(dto) {

        let retorno = await IndicadorPessoalBusiness.validarDadosAlteracaoIndicador(dto);
        if(retorno !== '') {
          return FrontBusiness.showConfirmacao(retorno, 'Deseja continuar?', 'checkbox', 'Tenho certeza que desejo continuar.').then(r => {
            if (r.isConfirmed) {
              return true;
            } else {
              return false
            }
          }).catch(this.showError)
        }
        return true;
      },

      importar() {
        this.camposConjuge.map(campo => {
          this.$set(this.form, campo, this.form.conjuge[campo]);
        });
        FrontBusiness.showSuccess('', 'Dados importados com sucesso');
      },

      async exportar() {
        this.camposConjuge.map(campo => {
          this.$set(this.form.conjuge, campo, this.form[campo]);
        })
        this.$set(this.form.conjuge, 'conjuge', {id: this.form.id});

        let dto = Utils.clone(this.form.conjuge);
        dto.indicadorPessoal = {
          id: dto.indicadorPessoalId,
          nome: dto.nome,
          documento: dto.documento,
          tipoPessoa: dto.tipoPessoa,
          telefone: dto.telefone,
          celular: dto.celular,
          comercial: dto.comercial,
          email: dto.email,
          documentoDesconhecido: dto?.indicadorPessoal?.documentoDesconhecido,
          fornecedor: dto?.indicadorPessoal?.fornecedor,
          substituicaoTributaria: dto?.indicadorPessoal?.substituicaoTributaria,
          banco: dto?.indicadorPessoal?.banco,
          agencia: dto?.indicadorPessoal?.agencia,
          conta: dto?.indicadorPessoal?.conta
        };
        dto.possuiUniaoEstavel = this.form.possuiUniaoEstavel === 'SIM' ? true : (this.form.possuiUniaoEstavel === 'NAO' ? false : null)

        let editar = this.$root.config.geral.desativarVersionamentoAutomatico;
        let conjuge = await IndicadorPessoalBusiness.criarVersao(dto.indicadorPessoalId, editar, dto).catch(this.showError);
        if(conjuge){
          this.$set(this.form, 'conjuge', conjuge);
          FrontBusiness.showSuccess('', 'Dados exportados com sucesso');
        }
      }
    }

  }
</script>

<style lang=scss>
  #form-indicador-pessoal {
    .card {
      hr {
        margin-top: .25rem;
      }

      .card-body {
        padding-bottom: 0;
      }
    }
  }
</style>
