import LacunaBusiness from "@/business/LacunaBusiness.js";
import Http from "@/commons/Http";
const path = 'api/arisp';

const getLacunaProps = async () => {

  let thumbprint = await LacunaBusiness.getThumb().catch(e => {
    console.error(e);
    return false;
  });

  if(!thumbprint){
    await Promise.reject("Erro ao tentar utilizar o certificado digital. Realize seu login novamente.");
    return;
  }

  const certEncoded = await LacunaBusiness.readCertificate(thumbprint).catch(e => {
    console.error(e);
    return false;
  });

  if(!certEncoded){
    await Promise.reject("Erro ao tentar ler o certificado digital. Verifique se está conectado corretamente.");
    return;
  }

  const auth = await LacunaBusiness.getLoginHash().catch(e => {
    console.error(e);
    return false;
  });

  if(!auth){
    await Promise.reject("Erro ao tentar utilizar o certificado digital. Realize seu login novamente.");
    return;
  }

  const signature = await LacunaBusiness.signData({
    thumbprint: thumbprint,
    data: auth.nonce,
    digestAlgorithm: auth.digestAlgorithm
  });

  return {cert : certEncoded, signature, nonce: auth.nonce, thumbprint, digestAlgorithm: auth.digestAlgorithm};

};

export default {

  statusAcompanhamentoOnr: Object.freeze([
    {id: 'A_1',  nome : "Título com reingresso"},
    {id: 'A_2',  nome : "Exame e cálculo cancelado"},
    {id: 'A_3',  nome : "Título pronto para retirada"},
    {id: 'A_4',  nome : "Título prenotado"},
    {id: 'A_5',  nome : "Exame e cálculo concluído"},
    {id: 'A_6',  nome : "Título registrado"},
    {id: 'A_7',  nome : "Nota de exigência"},
    {id: 'A_8',  nome : "Título entregue"},
    {id: 'A_9',  nome : "Suscitação de dúvida"},
    {id: 'A_10', nome : "Dúvida julgada procedente"},
    {id: 'A_11', nome : "Dúvida julgada improcedente"},
    {id: 'A_12', nome : "Exame e cálculo protocolado"},
    {id: 'A_13', nome : "Exame e cálculo pronto para a retirada"},
    {id: 'A_14', nome : "Bloqueio de matrículas"},
    {id: 'A_15', nome : "Prorrogado o prazo da prenotação"},
    {id: 'A_16', nome : "Prorrogado o prazo de entrega e devolução"},
    {id: 'A_17', nome : "Prorrogado o prazo da Penhora Online"},
    {id: 'A_18', nome : "Notificação"},
    {id: 'A_19', nome : "Prorrogado o prazo da Notificação"},
    {id: 'A_20', nome : "Processamento"},
    {id: 'A_21', nome : "Aguardando Pagamento"},
  ]),

  statusAcompanhamentoRib: Object.freeze([
    {id: 'A_1', nome : "Título com reingresso"},
    {id: 'A_2', nome : "Cancelado"},
    {id: 'A_3', nome : "Título pronto para retirada"},
    {id: 'A_4', nome : "Título prenotado"},
    {id: 'A_5', nome : "Exame e cálculo concluído"},
    {id: 'A_6', nome : "Título Registrado - não disponível para retirada"},
    {id: 'A_7', nome : "Nota de exigência"},
    {id: 'A_8', nome : "Título entregue"},
    {id: 'A_9', nome : "Suscitação de dúvida"},
    {id: 'A_10', nome : "Dúvida jugada procedente"},
    {id: 'A_11', nome : "Dúvida jugada improcedente"},
    {id: 'A_12', nome : "Exame e cálculo protocolado"},
    {id: 'A_13', nome : "Exame e cálculo pronto para a retirada"},
    {id: 'A_14', nome : "Bloqueio de matrículas"},
    {id: 'A_15', nome : "Prorrogado o prazo da prenotação"},
    {id: 'A_16', nome : "Prorrogado o prazo de entrega ou devolução"},
    {id: 'A_17', nome : "Prorrogado o prazo da Penhora online"},
    {id: 'A_18', nome : "Notificação"},
    {id: 'A_19', nome : "Prorrogado o prazo da Notificação"},
    {id: 'A_20', nome : "Processamento"}
  ]),

  async testarCertificado(){
    return await getLacunaProps();
  },

  async getAgendamentosAguardandoEnvio(dominio){
    return Http.get(`${path}/acompanhamento-registral/aguardando`);
  },

  async getPedidosAguardandoAtualizacao(dominio){
    return Http.get(`${path}/aguardando-atualizacao/${dominio}`);
  },

  async consultarHashsCnib(lacuna, challenge, token, documentos){
    // let lacuna = await getLacunaProps();
    lacuna.request = {
      challenge,
      token,
      documentos
    };
    return Http.post(`${path}/cnib?n=${lacuna.nonce}`, lacuna);
  },

  async consultarHashsCnibProtocoloLegado(lacuna, challenge, token, protocolo){
    // let lacuna = await getLacunaProps();
    lacuna.request = {
      challenge,
      token
    };
    return Http.post(`api/v2/cnib/legado/consulta-protocolo/${protocolo}?n=${lacuna.nonce}`, lacuna);
  },

  async consultarHashsCnibDocumentosLegado(lacuna, challenge, token, documentos){
    lacuna.request = {
      challenge,
      token
    };
    return Http.post(`api/v2/cnib/legado/consulta-documentos?n=${lacuna.nonce}`, {documentos, lacuna});
  },

  async consultarHashsCnibProtocolo(protocolo){
    return Http.post(`api/v2/cnib/consultar-protocolo/${protocolo}`);
  },

  async consultarHashsCnibDocumentos(documentos){
    return Http.post(`api/v2/cnib/consultar-documentos`, documentos);
  },

  async iniciarTokens(){
    let lacuna = await getLacunaProps();
    return Http.post(`${path}/iniciar-certificado?n=${lacuna.nonce}`, lacuna);
  },

  async pedidosPendentes(dominio){
    return Http.post(`${path}/pedidos-pendentes/${dominio}`);
  },

  async importarPedido(dominio, id){
    return Http.post(`${path}/importar-pedido/${dominio}/${id}`);
  },

  async atualizarProtocolo(protocolo){
    return Http.post(`${path}/atualizar-protocolo/${protocolo}`);
  },

}

