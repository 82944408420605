<template>
  <card :titulo="aba === 'abertas' ? 'Indisponibilidades abertas' : 'Histórico de ordens'" :classTitle="'active'">
    <template #acoes>
      <a @click="consultarCnib()" title="Consultar CNIB" class="icon float-right pointer mr-3">
        CONSULTAR CNIB
      </a>
      <slot name="menu"></slot>
    </template>

    <consulta :columns="columns" ref="tabela" :process="process" classe="tbl-consulta"
              label-lista-vazia="Não há indisponibilidades para exibir">

      <template #row="{ row }">

        <tr>
          <td class="text-truncate pr-2">{{row.cnib}}</td>
          <td class="text-truncate pr-2">{{nomearFicha(row)}}</td>

          <td class="nowrap" width="50px">
            <v-button :run="abrirDetalhes" :params="[row.ordem]" :button="false" class="icon text-secondary" title="Exibir Detalhes">
              <i class="fas fa-home"></i>
            </v-button>
          </td>

        </tr>

      </template>

    </consulta>
  </card>
</template>

<script>
import FrontBusiness from "@/business/FrontBusiness.js";
import IndisponibilidadeBusiness from "@/business/IndisponibilidadeBusiness.js";
import CnibPesquisaDocumento from "@/components/Arisp/CnibPesquisaDocumento.vue";
import Consulta from "@/components/Consultas/Consulta";
import DetalhesIndisponibilidade from "@/components/Indisponibilidade/DetalhesIndisponibilidade.vue";
import Card from "@/components/Layout/components/Card.vue";

export default {

  name: "ConsultaIndisponibilidade",
  components: {Consulta, Card},
  props: {
    pessoa : Object,
    aba : String
  },

  watch : {
    async aba(newValue){
      if (newValue === 'abertas') {
        this.filtros = { indicadorPessoalId: this.pessoa.indicadorPessoalId }
      } else {
        this.filtros = { documento: this.pessoa.documento }
      }
      await this.$refs?.tabela?.load();
    },
  },

  data() {
    return {
      filtros : {
        indicadorPessoalId: this.pessoa.indicadorPessoalId,
      },
      outros : []
    }
  },

  computed: {
    columns() {
      return [
        {label: 'Protocolo Indisponibilidade', field: 'v.cnib'},
        {label: 'Indicador', field: 'ir.codigo'},
        {label: '', field: null, headerClass: 'column__botao', name: 'botao'}
      ];
    },
  },

  async mounted(){
    await this.$refs?.tabela?.load();
  },

  methods:{

    nomearFicha(ficha) {
      return ficha?.livro ? FrontBusiness.nomearFicha(ficha) : '';
    },

    abrirDetalhes(id) {
      FrontBusiness.openModal(DetalhesIndisponibilidade, {id});
    },

    async process(sort_by, sort_dir, page_length, page_number){
      let result = await IndisponibilidadeBusiness.paginationIndicadorPessoal(sort_by, sort_dir, page_number, page_length, {filtros: this.filtros});
      return {rows: result.pagina, total: result.quantidade};
    },

    async consultarCnib() {
      await FrontBusiness.openModal(CnibPesquisaDocumento, {documentos: [this.pessoa.documento], consulta: true});
    }

  }
}
</script>

<style lang=scss>
.tbl-consulta {
  .modal-body {
    padding: 0;
  }
}
</style>
