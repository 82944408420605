<template>
  <card :titulo="titulo">
    <div style="margin-top: -10px;" v-if="ato.indicadorGarantias.length && showValor">
      <div class="text-muted cell-top" style="min-width: 34px;" v-if="edicao"></div>
      <div class="text-muted cell-top" :class="{'pl-0' : edicao}" style="width: 100%">Bem Móvel</div>
      <div class="text-muted cell-top" style="min-width: 200px" v-if="showValor">Valor da Garantia</div>
      <div class="text-muted cell-top" style="min-width: 34px"></div>
    </div>
    <div v-if="!ato.indicadorGarantias.length" class="alert alert-outline-secondary alert-sm mb-1">
      Nenhuma bem móvel cadastrado
    </div>
    <div v-for="(bemGarantia, i) in ato.indicadorGarantias" class="row-e" :key="`${i}-${bemGarantia.id}`">
      <div class="cell move text-left" style="min-width: 20px;" v-if="edicao">
        <a @click.prevent="editar(bemGarantia.bemMovel.id, i)" class="icon" style="position: relative; top: calc(50% - 12px);"><i class="fal fa-edit"></i></a>
      </div>

      <div class="cell" style="width: 100%" :class="{'pl-0' : edicao}">
        <div>
          <a href class="mb-1 pointer icon mr-2" @click.prevent="detalhes(bemGarantia.bemMovel.id, 'BEM_MOVEL')">
            {{nomearFicha(bemGarantia.bemMovel)}}
          </a>
        </div>
        <div class="small text-muted">
          {{bemGarantia.bemMovel.tipoBem.nome}} - {{bemGarantia.bemMovel.nome}}
        </div>
      </div>
      <div v-if="showValor" class="cell" style="min-width: 200px">
        <v-input :disabled="!edicao" v-model="bemGarantia.valor" type="currency" :input-props="{precision : 2}" />
      </div>
      <div class="cell text-right" style="min-width: 34px">
        <div class="ato-action" v-if="edicao">
          <a @click.prevent="remover(i)" class="icon"><i class="fa fa-times"></i></a>
        </div>
      </div>
    </div>

    <div v-if="addFicha" style="padding: 0.5rem 0;" class="row gutters-xs w-100">
      <div class="col">
        <buscar-fichas livro="BEM_MOVEL" status="ABERTA" ref="buscarFichas" @selecionar="selecionar($event, 'indicadorGarantias')" :multiple="multiple"
                       :selecionarLivros="false" :search="true" :noChangeSelect="true" />
        <p class="small-2 text-muted ml-2" style="margin-top: -10px;"
           v-if="$refs.buscarFichas && !$refs.buscarFichas.pesquisando && ($refs.buscarFichas.individual.codigo || $refs.buscarFichas.str) && !$refs.buscarFichas.mensagemValidacao">
          Pressione enter para adicionar o bem móvel aos Bens e Garantias.
        </p>
      </div>
      <div class="col-auto">
        <button class="btn btn-outline-secondary btn-xs" @click="criar()" title="Adicionar Bem Móvel" type="button"
                id="btn-criar-indicador-pessoal">
          <i class="fa fa-plus"></i>
        </button>
      </div>
    </div>
  </card>
</template>

<script>
import FrontBusiness from "@/business/FrontBusiness.js";
import IndicadorBusiness from "@/business/indicadores/IndicadorBusiness.js";
import Card from "@/components/Layout/components/Card.vue";
import FormBemMovel from "@/components/BemMovel/Form/FormBemMovel.vue";
import bemMovelBusiness from "@/business/crud/BemMovelBusiness";
import DominioBusiness from "@/business/protocolo/DominioBusiness";

export default {

    name: "BensGarantias",
    components: {Card},
    props: {
      ato: {},
      edicao: {default: false},
      multiple: {default: true}
    },

    data(){
      return {
        configuracao: {},
      }
    },

  computed:{
    showValor(){
      let livro = this.ato.tipoServico.dominio === "PROTOCOLO_RTD" ? 'livros' : 'livro' ;
      return !(this.ato.tipoServico.opcoes[livro]?.includes('LIVRO_BENS') && !this.ato.indicador);
    },
    titulo(){
      return this.showValor ? 'Bens e Garantias' : 'Bem Móvel';
    },
    addFicha(){
      if (!this.multiple) {
        return this.ato.indicadorGarantias.length < 1;
      }
      return this.edicao
    }
  },

  methods : {
      nomearFicha: FrontBusiness.nomearFicha,

      detalhes(id, livro){
        FrontBusiness.openModal(IndicadorBusiness.getDetalhesIndicador(livro), {id});
      },

      async selecionar(itens){
        this.$nextTick(() => {
          itens?.forEach(async (item) => {
            if (item?.id && !this.ato.indicadorGarantias.find(u => u.bemMovel.id === item.id)) {
              item = await bemMovelBusiness.getById(item.id)
              this.ato.indicadorGarantias.push({
                bemMovel: item,
                valor: 0
              })
            }
          });
        });
        this.$refs.buscarFichas.str = "";
        this.$refs.buscarFichas.individual.codigo = "";
        this.$forceUpdate();
      },

      remover(idx) {
        this.$nextTick(() => {
          this.ato.indicadorGarantias.splice(idx, 1);
        });
        this.$forceUpdate();
      },

      async criar(){
        let bemMovel = await FrontBusiness.openModal(FormBemMovel, {atribuicao: DominioBusiness.getDominio(this.ato.tipoServico.dominio).atribuicao});
        if(bemMovel){
          this.selecionar([bemMovel]);
        }
        FrontBusiness.setFocus(this.$el, 'btn-criar-indicador-pessoal');
      },

      atualizar(i, bemGarantia) {
        this.remover(i, false);
        this.$nextTick(() => {
          this.ato.indicadorGarantias.splice(i, 0, bemGarantia);
        });
        this.$forceUpdate();

      },
      async editar(bemMovel, i) {

        if (!this.edicao) {
          return;
        }

        let retorno = await FrontBusiness.openModal(FormBemMovel, {id: bemMovel, atribuicao: DominioBusiness.getDominio(this.ato.tipoServico.dominio).atribuicao});
        if (retorno) {
          this.atualizar(i, {
            valor: this.ato.indicadorGarantias[i].valor || 0,
            bemMovel: retorno,
          });
        }

      },

      duplicar(bemGarantia) {
        this.selecionar([bemGarantia?.bemMovel])
      },
    },

  }
</script>

<style lang=scss>
.cell-top {
  display: table-cell;
  vertical-align: middle;
  padding: 0.75rem;
  font-weight: bold;
}
.row-e {
  /*display: table-row;*/
  vertical-align: inherit;
  border-color: inherit;
  background: rgb(35, 41, 49);

  .cell {
    display: table-cell;
    vertical-align: middle;
    border-bottom: 5px solid #1c1e24;
    padding: 0.75rem;
    .form-group {
      margin-bottom: 0;
    }
  }
}

.theme-light{
  .row-e {
    background-color: #fff;
    .cell {
      border-bottom-color: #F1F1F2;
    }
  }
}
</style>
