<template>
  <modal titulo="Justificar DAJE">
    <card>
      <v-input type="v-select" :starts-with="true" :options="motivos"
               :error="validation.motivo" v-model="form.motivo" label="Motivo"/>
    </card>

    <template #footer>

      <button type="button" @click.prevent="close()" class="btn btn-outline-secondary mr-auto">Cancelar</button>
      <v-button :run="run" :popover="true" class="btn-outline-primary">Salvar</v-button>

    </template>

  </modal>
</template>

<script>
  import FrontBusiness from "@/business/FrontBusiness";
  import CardContent from "@/components/Layout/components/CardContent.vue";
  import Modal from "@/components/Modal";
  import SmallLoading from "@/components/SmallLoading.vue";
  import Card from "@/components/Layout/components/Card.vue";
  import Utils from "@/commons/Utils";

  export default {
    name: "ModalJustificarDaje",
    components: {Card, SmallLoading, Modal, CardContent},
    props: {
      item : Object,
      protocolo : Object,
    },
    data() {
      return {
        form: {},
        validation: {},
        motivos: []
      }
    },

    modal: {
      width: 900
    },

    async mounted() {
      this.motivos = await this.$api.get(`/api/v2/regras-estaduais/BA/justificativas-daje`)
        .catch(e => {
          FrontBusiness.showError('Erro ao tentar carregar motivos', e?.message || e);
        });

      console.log(this.motivos)
      this.loading = false;
    },

    computed:{

    },

    methods:{

      close(resultado) {
        FrontBusiness.closeModal(resultado);
      },

      async run() {
        let guia = `${this.item.codigoEmissor}.${this.item.serie}.${this.item.numSequencial}`;
        let validated = true;
        this.$set(this, 'validation', {});

        let dto = Utils.clone(this.form);

        let obrigatorios = ['motivo'];

        obrigatorios.forEach(e => {
          if (!dto[e]) {
            this.$set(this.validation, e, 'Campo obrigatório');
            validated = false;
          }
        });

        if(validated) {
          let response = await this.$api.get(`/api/v2/regras-estaduais/BA/${this.protocolo.id}/justificar-daje/${dto.motivo}/${guia}`)
            .catch(e => {
              FrontBusiness.showError('Erro ao tentar justificar DAJE', e?.message || e);
            });
          if(response){
            FrontBusiness.showSuccess('', "DAJE justificado com sucesso.")
            this.close()
          }
        }
      },

    }
  }
</script>

<style>

</style>
